import "./assets/css/style.css";
import "./assets/css/style.min.css";
import "./assets/css/vendor/plugins.css";
import "./assets/css/vendor/plugins.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/css/plugins/apexcharts.css";
import "./assets/css/plugins/flaticon.css";
import "./assets/css/plugins/font-awesome.min.css";
import "./assets/css/plugins/icofont.min.css";
import "./assets/css/plugins/jqvmap.min.css";
import "./assets/css/plugins/magnific-popup.css";
import "./assets/css/plugins/nice-select.css";
import "./assets/css/plugins/swiper-bundle.min.css";
import "./assets/css/custom.css";
import "./assets/css/course.css";
import {BrowserRouter} from "react-router-dom";
import {PageWrapper} from "./components/elements/Wrapper/PageWrapper";
import {routes} from "./routes";
import {QueryClient, QueryClientProvider} from "react-query";
import {createContext, useEffect, useMemo, useState} from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20 * 1000,
    },
  },
});
export const CartContext = createContext(null);

function App() {
  const [cart, setCart] = useState([]);
  const handleSetCart = (value) => {
    console.log(value);
    setCart(value)
  }
  const providerValue = useMemo(() => ({cart, handleSetCart}), [cart, handleSetCart]);
  useEffect(() => {
    const cart = localStorage.getItem('cart');
    if (!cart) {
      const emptyCart = [];
      localStorage.setItem('cart', JSON.stringify(emptyCart));
    }else{
      setCart(JSON.parse(cart));
    }
  }, []);


  return (
    <CartContext.Provider value={providerValue}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <PageWrapper>{routes}</PageWrapper>
        </BrowserRouter>
      </QueryClientProvider>
    </CartContext.Provider>
  );
}

export default App;
