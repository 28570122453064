import React from "react";
import SectionHero from "../elements/SectionHero";
import CourseDetailsInfo from "../elements/CourseDetails/CourseDetailsInfo";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import courses from "../../services/courses";

const CourseDetails = () => {
  const { courseId } = useParams();
  const { isLoading, data } = useQuery({
    queryKey: ["course_details", courseId],
    queryFn: () => courses.getSingleCourseDetails(courseId),
    keepPreviousData: true,
  });
  return (
    <>
      <SectionHero
        page_name={isLoading ? "" : data.title}
        description={isLoading ? "" : data.title}
        description_light={""}
      />
      <CourseDetailsInfo isLoading={isLoading} data={data} />
    </>
  );
};

export default CourseDetails;
