import React from "react";
import { VIDEODETAILSMENU } from "../../../settings/enums";

const Menu = ({ tab, setTab, menu }) => {
  return (
    <nav className="enroll-tab-menu">
      <ul className="nav">
        {Object.keys(menu).map((item, i) => (
          <li key={i}>
            <button
              tabIndex="0"
              aria-label={menu[item]}
              key={i}
              onClick={() => setTab(menu[item])}
              className={tab === menu[item] ? "active" : ""}
              id="video-content-tabs"
              data-bs-toggle="tab"
              data-bs-target="#tab1"
            >
              {menu[item]}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
