import React, { useState } from "react";
import { Footer } from "../Footer";
import Header from "../Headers/Header";
import MobileMenu from "../MobileMenu";

export const PageWrapper = (props) => {
  const [menu_open, setMenu_open] = useState(false);

  return (
    <div className="main-wrapper">
      <Header setMenu_open={setMenu_open} />
      <MobileMenu menu_open={menu_open} setMenu_open={setMenu_open} />
      <div
        onClick={() => setMenu_open(false)}
        className={`overlay ${menu_open ? "open" : ""}`}
      />
      {props.children}
      <Footer />
    </div>
  );
};
