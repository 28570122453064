import React from "react";
import SectionHero from "../elements/SectionHero";
import MessagesBox from "../elements/Messages/MessagesBox";

const Messages = () => {
  return (
    <>
      <SectionHero
        page_name={"Moje wiadomości"}
        // description={"Cześć, Piotr!"}
        // description_light={"Witaj ponownie!"}
      />
      <MessagesBox />
    </>
  );
};

export default Messages;
