import React from "react";
import { VideoContent } from "../elements/SingleCourse/VideoContent";
import { VideoDetails } from "../elements/SingleCourse/VideoDetails/VideoDetails";
import { useQuery } from "react-query";
import courses from "../../services/courses";
import { LIVECOURSEMENU } from "../settings/enums";

const LiveCourse = () => {
  return (
    <>
      <div className="header-container-course" />
      <div className="section">
        <div className="courses-enroll-wrapper">
          <div style={{ margin: "auto" }} className="courses-video-player">
            <VideoContent chapter_id={1} />
            <VideoDetails menu={LIVECOURSEMENU} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveCourse;
