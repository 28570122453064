import config from "../config";
import authHeader from "../helper/auth-header.js";
import { handleResponse } from "../helper/handle-response.js";

const getThreadsList = async (course_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth },
  };

  return fetch(`${config.apiUrl}/forum/${course_id}/`, requestOptions).then(
    (r) => {
      return handleResponse(r, requestOptions).then((r) => {
        return r.json();
      });
    }
  );
};

const createNewThread = async ({ title, forum }) => {
  const auth = authHeader();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth },
    body: JSON.stringify({ title, forum }),
  };

  return fetch(`${config.apiUrl}/forum/threads/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default { getThreadsList, createNewThread };
