import React from "react";
import { LoginBox } from "../elements/Login/LoginBox";
import SectionHero from "../elements/SectionHero";

const Login = () => {
  return (
    <>
      <SectionHero page_name={"Zaloguj się"} description={"Masz już konto ?"} />
      <LoginBox />
    </>
  );
};

export default Login;
