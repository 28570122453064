import React from "react";
import SectionHero from "../elements/SectionHero";
import MyOwnedCourses from "../elements/MyCourses/MyOwnedCourses.js";
import OtherAvaibleCourses from "../elements/MyCourses/OtherAvaibleCourses.js";

const MyCourses = () => {
  return (
    <>
      <SectionHero
        page_name={"Moje kursy"}
        description={"Cześć, Piotr!"}
        description_light={"Witaj ponownie!"}
      />
      <MyOwnedCourses />
      <OtherAvaibleCourses />
    </>
  );
};

export default MyCourses;
