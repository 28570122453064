import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";
import courses from "../../../../../services/courses.js";
import { useQuery } from "react-query";
const CoursesMenu = ({ setSelectedTag, selectedTag }) => {
	const { isLoading, data } = useQuery({
		queryKey: ["tags"],
		queryFn: () => courses.getCoursesTags(),
		keepPreviousData: true,
	});

	const swiperRef = useRef();
	const sliderSettings = {
		0: {
			slidesPerView: 1,
		},
		576: {
			slidesPerView: 2,
		},
		768: {
			slidesPerView: 3,
		},
		992: {
			slidesPerView: 4,
		},
		1200: {
			slidesPerView: 5,
		},
	};
	if (isLoading || data.results.length == 0) {
		return null;
	}
	return (
		<div className="courses-tabs-menu courses-active">
			<div className="swiper-container">
				<Swiper
					spaceBetween={30}
					slidesPerView={5}
					onSlideChange={() => console.log("slide change")}
					onSwiper={(swiper) => console.log(swiper)}
					onBeforeInit={(swiper) => {
						swiperRef.current = swiper;
					}}
					breakpoints={sliderSettings}>
					{data.results.map(({ name }, index) => {
						return (
							<SwiperSlide key={index}>
								<button
									style={{ zIndex: "1" }}
									onClick={() => {
										setSelectedTag(name);
										console.log(name);
									}}
									className={`courses-button-menu ${
										name == selectedTag ? "courses-button-menu-active" : ""
									}`}>
									{name}
								</button>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
			<div
				onClick={() => swiperRef.current?.slideNext()}
				className="swiper-button-next">
				<i className="icofont-rounded-right" />
			</div>
			<div
				onClick={() => swiperRef.current?.slidePrev()}
				className="swiper-button-prev">
				<i className="icofont-rounded-left" />
			</div>
		</div>
	);
};

export default CoursesMenu;
