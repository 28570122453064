import React from "react";
import register_login from "../../assets/images/register-login.png";
import shape_26 from "../../assets/images/shape/shape-26.png";

const RegisterLoginImage = () => {
	return (
		<div className="col-lg-6">
			{/* Register & Login Images Start */}
			<div className="register-login-images">
				<div className="shape-1">
					<img
						src={shape_26}
						alt="Shape"
					/>
				</div>
				<div className="images">
					<img
						src={register_login}
						alt="Register Login"
					/>
				</div>
			</div>
			{/* Register & Login Images End */}
		</div>
	);
};

export default RegisterLoginImage;
