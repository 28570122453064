import React from "react";
import AllCourses from "../elements/Main/AllCourses/AllCourses";
import SectionHero from "../elements/SectionHero";

const Courses = () => {
  return (
    <>
      <SectionHero
        page_name={"Katalog kursów"}
        description={"Sprawdź wszystkie "}
        description_light={"nasze kursy."}
      />
      <AllCourses marginBottom />
    </>
  );
};

export default Courses;
