import React from 'react';

const PreloaderComponent = (props) => {
    return <div className={`preloader ${props.loading === true ? '' : 'd-none'}`} style={props.style}>
        <div className="lds-ripple">
            <div className="lds-pos" />
            <div className="lds-pos" />
        </div>
    </div>
};

export default PreloaderComponent;
