import example_image from "../../../../assets/images/author/author-06.jpg";

const SingleBoxinfo = () => {
	return (
		<div
			// style={{ width: "100%" }}
			className="single-testimonial swiper-slide">
			<div className="testimonial-author">
				<div className="author-thumb">
					<img
						src={example_image}
						alt="Author"
					/>
					<i className="icofont-quote-left" />
				</div>
				<span className="rating-star">
					<span
						className="rating-bar"
						style={{ width: "80%" }}
					/>
				</span>
			</div>
			<div className="testimonial-content">
				<p>
					Lorem Ipsum has been the industry's standard dummy text since the
					1500s, when an unknown printer took a galley of type and scrambled it
					to make type specimen book has survived not five centuries but also
					the leap into electronic.
				</p>
				<h4 className="name">Melissa Roberts</h4>
				<span className="designation">Product Designer, USA</span>
			</div>
		</div>
	);
};

export default SingleBoxinfo;
