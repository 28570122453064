import React, { useEffect, useState } from "react";
import Thread from "./Thread.js";
import exampleUserMessageAvatar from "../../../assets/images/examplemessageuser.png";
import forum from "../../../services/forum.js";

const Forum = () => {
  const [activeThread, setActiveThread] = useState();

  useEffect(() => {
    forum.getThreadsList(1).then((r) => {
      console.log(r);
    });
  }, []);

  return (
    <div className="container">
      <h4>Forum</h4>
      <div style={{ paddingLeft: 0 }} className="main-content-wrapper pb-0">
        <div style={{ padding: 0 }} className="container-fluid">
          {/* Admin Top Bar End */}
          {/* Question & Answer End */}
          <div className="question-answer">
            <div className="row">
              <div className="col-xl-4">
                {/* Answer User List Start */}
                <div className="answer-user-list">
                  <button className="btn btn-primary btn-hover-dark">
                    Dodaj wątek
                  </button>

                  <Thread
                    activeThread={activeThread}
                    setActiveThread={setActiveThread}
                  />
                </div>
              </div>
              <div className="col-xl-8">
                <div className="answer-message-wrapper">
                  <ul>
                    <li>
                      <div className="single-message">
                        <div className="message-author">
                          <div className="author-images">
                            <img src={exampleUserMessageAvatar} alt="Author" />
                          </div>
                          <div className="author-content">
                            <h6 className="name">
                              <strong>Natosha Sibley</strong>{" "}
                            </h6>
                          </div>
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s
                          when an unknown printer took a galley of type and
                          scrambled it to make specimen book has survived not
                          only five centuries.
                        </p>
                      </div>
                      {/* Single Message End */}
                      <ul className="message-replay">
                        <li>
                          {/* Single Message Start */}
                          <div className="single-message">
                            <div className="message-author">
                              <div className="author-images">
                                <img
                                  src={exampleUserMessageAvatar}
                                  alt="Author"
                                />
                              </div>
                              <div className="author-content">
                                <h6 className="name">
                                  <strong>Ashley Reeves</strong>{" "}
                                  <span className="instructor">Instructor</span>
                                </h6>
                                <span className="time">
                                  Asked: March 28, 2021
                                </span>
                              </div>
                              <div className="meta">
                                <a className="answer" href="#">
                                  <i className="icofont-ui-messaging" /> Answer
                                </a>
                              </div>
                            </div>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s when an unknown printer took a galley of
                              type and scrambled it to make specimen book has
                              survived not only five centuries.
                            </p>
                          </div>
                          {/* Single Message End */}
                        </li>
                      </ul>
                    </li>
                    <li>
                      {/* Single Message Start */}
                      <div className="single-message">
                        <div className="message-author">
                          <div className="author-images">
                            <img src={exampleUserMessageAvatar} alt="Author" />
                          </div>
                          <div className="author-content">
                            <h6 className="name">
                              <strong>Natosha Sibley</strong>
                            </h6>
                            <span className="time">Asked: March 28, 2021</span>
                          </div>
                          <div className="meta">
                            <a className="answer" href="#">
                              <i className="icofont-ui-messaging" /> Answer
                            </a>
                          </div>
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s
                          when an unknown printer took a galley of type and
                          scrambled it to make specimen book has survived not
                          only five centuries.
                        </p>
                      </div>
                      {/* Single Message End */}
                    </li>
                  </ul>
                  <a className="loadmore" href="#">
                    Loard more 22 answer
                  </a>
                </div>
                {/* Answer Message Wrapper End */}
              </div>
            </div>
          </div>
          {/* Question & Answer End */}
        </div>
      </div>
    </div>
  );
};

export default Forum;
