import React from "react";

const Terms = () => {
  return (
    <>
      <main className="privacy container">
        <h1 style={{ textAlign: "left" }}>Regulamin</h1>
        <p>
          The privacy policy describes the rules for the processing of
          information about you, including personal data and cookies. 1. General
          information This policy applies to the Website operating at the url:
          https://lightcode.eu The website operator and the personal data
          administrator is: Light Code Sp. z o.o. ul. Legnicka 57W / 17, 54-203
          Wrocław Operator’s e-mail contact address: info@lightcode.eu The
          operator is the administrator of your personal data in relation to the
          data provided voluntarily on the website. The website uses personal
          data for the following purposes: Keeping and sending a newsletter
          Conducting online chat conversations Running a system of
          advertisements Handling inquiries via the form Implementation of
          ordered services Presentation of the offer or information The website
          obtains information about users and their behavior in the following
          way: Through data entered voluntarily in forms, which are entered into
          the Operator’s systems. By saving cookie files in end-devices
          (so-called “cookies”). 2. Selected data protection methods used by the
          Operator The places of logging in and entering personal data are
          protected in the transmission layer (SSL certificate). As a result,
          personal data and login data entered on the website are encrypted on
          the user’s computer and can only be read on the target server. The
          operator periodically changes his administrative passwords. In order
          to protect data, the Operator regularly makes backup copies. An
          important element of data protection is regular updating of all
          software used by the Operator to process personal data, which in
          particular means regular updates of programming components. 3. Hosting
          The website is hosted (technically maintained) on the operator’s
          server: LH.pl Sp. z o.o. 4. Your rights and additional information on
          how the data is used In some situations, the Administrator has the
          right to transfer your personal data to other recipients, if it is
          necessary to perform the contract concluded with you or to fulfill the
          obligations incumbent on the Administrator. This applies to such
          groups of recipients: hosting company on an entrustment basis
          operators of online chat solutions authorized employees and associates
          who use the data to achieve the purpose of the website companies
          providing marketing services to the Administrator Your personal data
          processed by the Administrator for no longer than it is necessary to
          perform the related activities specified in separate regulations (e.g.
          on accounting). With regard to marketing data, the data will not be
          processed for more than 3 years. You have the right to request from
          the Administrator: access to your personal data, rectify the data,
          removal, processing restrictions, and data portability. You have the
          right to object to the processing indicated in point 3.3 c) to the
          processing of personal data in order to perform the legitimate
          interests pursued by the Administrator, including profiling, but the
          right to object may not be exercised if there are valid legally
          justified grounds processing of your interests, rights and freedoms,
          in particular establishing, investigating or defending claims. The
          Administrator’s actions may be appealed against to the Prezes Urzędu
          Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa. Providing
          personal data is voluntary, but necessary to operate the Website. In
          relation to you, actions may be taken consisting in automated decision
          making, including profiling to provide services under the concluded
          contract and for the purpose of conducting direct marketing by the
          Administrator. Personal data is not transferred from third countries
          within the meaning of the provisions on the protection of personal
          data. This means that we do not send them outside the European Union.
          5. Information in the forms The website collects information provided
          voluntarily by the user, including personal data, if provided. The
          website may save information about connection parameters (time stamp,
          IP address). The website, in some cases, may save information
          facilitating the linking of data in the form with the e-mail address
          of the user completing the form. In this case, the user’s e-mail
          address appears inside the url of the page containing the form. The
          data provided in the form are processed for the purpose resulting from
          the function of a specific form, e.g. in order to process the service
          request or commercial contact, service registration, etc. Each time
          the context and description of the form clearly informs what it is
          used for. 6. Administrator logs Information on the behavior of users
          on the website may be subject to logging. These data are used to
          administer the website. 7. Important Marketing Techniques The operator
          uses statistical analysis of website traffic through Google Analytics
          (Google Inc. based in the USA). The operator does not provide personal
          data to the operator of this service, but only anonymised information.
          The service is based on the use of cookies on the user’s end device.
          In terms of information about user preferences collected by the Google
          advertising network, the user can view and edit information derived
          from cookies using the tool: https://www.google.com/ads/preferences/
          The operator uses remarketing techniques that allow for adjusting
          advertising messages to the user’s behavior on the website, which may
          give the illusion that the user’s personal data is used to track him,
          but in practice no personal data is transferred from the Operator to
          advertising operators. The technological condition for such activities
          is that cookies are enabled. The operator uses the Facebook pixel.
          This technology makes Facebook (Facebook Inc. based in the USA) know
          that a given person registered in it uses the Website. In this case,
          it is based on data for which he is the administrator himself, the
          Operator does not provide any additional personal data to Facebook.
          The service is based on the use of cookies on the user’s end device.
          The operator uses a solution that studies user behavior by creating
          heat maps and recording behavior on the website. This information is
          anonymized before it is sent to the service operator so that it does
          not know what natural person it concerns. In particular, passwords and
          other personal data are not recorded. The operator uses a solution
          that automates the operation of the Website in relation to users, e.g.
          that can send an email to the user after visiting a specific subpage,
          provided that he has agreed to receive commercial correspondence from
          the Operator. 8. Information about cookies The website uses cookies.
          Cookie files (so-called “cookies”) are IT data, in particular text
          files, which are stored on the Website User’s end device and are
          intended for using the Website’s pages. Cookies usually contain the
          name of the website they come from, the storage time on the end device
          and a unique number. The entity placing cookies on the Website User’s
          end device and accessing them is the Website operator. Cookies are
          used for the following purposes: maintaining the Website user’s
          session (after logging in), thanks to which the user does not have to
          re-enter the login and password on each subpage of the Website;
          achieving the goals set out above in the section “Important marketing
          techniques”; The Website uses two basic types of cookies: session
          cookies and persistent cookies. Session cookies are temporary files
          that are stored on the User’s end device until logging out, leaving
          the website or turning off the software (web browser). Persistent
          cookies are stored on the User’s end device for the time specified in
          the cookie file parameters or until they are deleted by the User.
          Software for browsing websites (web browser) usually allows cookies to
          be stored on the User’s end device by default. Website Users can
          change the settings in this regard. The web browser allows you to
          delete cookies. It is also possible to automatically block
          cookies.Detailed information on this subject can be found in the help
          or documentation of the web browser. Restrictions on the use of
          cookies may affect some of the functionalities available on the
          Website pages. Cookies placed on the Website User’s end device may
          also be used by entities cooperating with the Website operator, in
          particular the following companies: Google (Google Inc. based in the
          USA), Facebook (Facebook Inc. based in the USA), Twitter (Twitter Inc.
          based in the USA).
        </p>
      </main>
    </>
  );
};

export default Terms;
