import React, { useState } from "react";
import { AccordionItem } from "./AccordionItem";

export const CourseContent = ({
  videoDeatil,
  data,
  display,
  setDisplay,
  activeMenu,
  setActiveMenu,
  setCurrentWatchingVideo,
}) => {
  return (
    <div
      style={videoDeatil ? { width: "426px" } : {}}
      className="courses-video-playlist"
    >
      <div className="playlist-title">
        <h3 className="title">Zawartość kursu</h3>
        <span>
          {data.total_duration} ({data.lessons_count} lekcje)
        </span>
      </div>
      <div className="video-playlist">
        <div className="accordion" id="videoPlaylist">
          {data.course_chapters.map((module, index) => {
            return (
              <AccordionItem
                setCurrentWatchingVideo={setCurrentWatchingVideo}
                display={display}
                setDisplay={setDisplay}
                key={index}
                module={module}
                moduleNumber={index}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
