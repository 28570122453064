import React from "react";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  return (
    <a
      style={{
        color: "#2C2C2C",
        fontSize: "14px",
        fontFamily: "Inter",
        textDecorationLine: "underline",
      }}
      onClick={() => navigate("/api/user/confirm-password-reset/?type=email")}
    >
      Zapomniałeś hasła?
    </a>
  );
};

export default ForgotPassword;
