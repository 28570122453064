import React from "react";
import SingleMember from "./SingleMember";
import example_image from "../../../../assets/images/author/author-01.jpg";

const Teams_Members = () => {
  const members = [
    {
      name: "Margarita James",
      designation: "MSC, Instructor",
      rating: "4.9",
      image: example_image,
    },
    {
      name: "Margarita James",
      designation: "MSC, Instructor",
      rating: "4.9",
      image: example_image,
    },
    {
      name: "Margarita James",
      designation: "MSC, Instructor",
      rating: "4.9",
      image: example_image,
    },
    {
      name: "Margarita James",
      designation: "MSC, Instructor",
      rating: "4.9",
      image: example_image,
    },
    {
      name: "Margarita James",
      designation: "MSC, Instructor",
      rating: "4.9",
      image: example_image,
    },
  ];

  return (
    <>
      <div className="section section-padding mt-n1">
        <div className="container">
          {/* Section Title Start */}
          <div className="section-title shape-03 text-center">
            <h5 className="sub-title">Nasz zespół</h5>
            <h2 className="main-title">Doświadczona kadra prowadzących</h2>
          </div>
          {/* Section Title End */}
          {/* Team Wrapper Start */}
          <div className="team-wrapper">
            <div className="row row-cols-lg-5 row-cols-sm-3 row-cols-2 ">
              {members.map((member, index) => {
                return <SingleMember key={index} {...member} />;
              })}
            </div>
          </div>
          {/* Team Wrapper End */}
        </div>
      </div>
    </>
  );
};

export default Teams_Members;
