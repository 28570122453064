import { mainMenu } from "../settings/menu";
import { useLocation, useNavigate } from "react-router-dom";

const MobileMenu = ({ menu_open, setMenu_open }) => {
  const navigate = useNavigate();
  let location = useLocation();

  return (
    <div
      className={`mobile-menu ${menu_open ? "open" : ""}`}
      aria-hidden={open ? "false" : "true"}
    >
      {/* Menu Close Start */}
      <a onClick={() => setMenu_open(false)} className="menu-close" href="/#">
        <i className="icofont-close-line" />
      </a>
      {/* Menu Close End */}
      {/* Mobile Top Medal Start */}
      <div className="mobile-top">
        <p>
          <i className="flaticon-phone-call" />{" "}
          <a href="tel:9702621413">(970) 262-1413</a>
        </p>
        <p>
          <i className="flaticon-email" />{" "}
          <a href="mailto:address@gmail.com">address@gmail.com</a>
        </p>
      </div>
      {/* Mobile Top Medal End */}
      {/* Mobile Sing In & Up Start */}
      <div className="mobile-sign-in-up">
        <ul>
          <li>
            <a className="sign-in" href="login.html">
              Sign In
            </a>
          </li>
          <li>
            <a className="sign-up" href="register.html">
              Sign Up
            </a>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-items">
        <ul className="nav-menu">
          {Object.keys(mainMenu).map((item, i) => (
            <li key={i}>
              <a
                style={
                  location.pathname === `/${mainMenu[item].url}`
                    ? { color: "#005D92" }
                    : {}
                }
                onClick={(e) => {
                  e.preventDefault();

                  navigate(`/${mainMenu[item].url}`);
                }}
                href={`/${mainMenu[item].url}`}
              >
                {mainMenu[item].title}
              </a>
            </li>
          ))}
          {/* <li>
            <a href="index.html">Home</a>
          </li>
          <li>
            <a href="/#">All Course</a>
            <ul className="sub-menu">
              <li>
                <a href="courses.html">Courses</a>
              </li>
              <li>
                <a href="courses-details.html">Courses Details</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/#">Pages </a>
            <ul className="sub-menu">
              <li>
                <a href="about.html">About</a>
              </li>
              <li>
                <a href="register.html">Register</a>
              </li>
              <li>
                <a href="login.html">Login</a>
              </li>
              <li>
                <a href="faq.html">FAQ</a>
              </li>
              <li>
                <a href="404-error.html">404 Error</a>
              </li>
              <li>
                <a href="after-enroll.html">After Enroll</a>
              </li>
              <li>
                <a href="courses-admin.html">
                  Instructor Dashboard (Course List)
                </a>
              </li>
              <li>
                <a href="overview.html">Instructor Dashboard (Performance)</a>
              </li>
              <li>
                <a href="students.html">Students</a>
              </li>
              <li>
                <a href="reviews.html">Reviews</a>
              </li>
              <li>
                <a href="engagement.html">Course engagement</a>
              </li>
              <li>
                <a href="traffic-conversion.html">Traffic &amp; conversion</a>
              </li>
              <li>
                <a href="messages.html">Messages</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/#">Blog</a>
            <ul className="sub-menu">
              <li>
                <a href="/#">Blog</a>
                <ul className="sub-menu">
                  <li>
                    <a href="blog-grid.html">Blog</a>
                  </li>
                  <li>
                    <a href="blog-left-sidebar.html">Blog Left Sidebar</a>
                  </li>
                  <li>
                    <a href="blog-right-sidebar.html">Blog Right Sidebar</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/#">Blog Details</a>
                <ul className="sub-menu">
                  <li>
                    <a href="blog-details-left-sidebar.html">
                      Blog Details Left Sidebar
                    </a>
                  </li>
                  <li>
                    <a href="blog-details-right-sidebar.html">
                      Blog Details Right Sidebar
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href="contact.html">Contact</a>
          </li> */}
        </ul>
      </div>
      <div className="mobile-social">
        <ul className="social">
          <li>
            <a href="/#">
              <i className="flaticon-facebook" />
            </a>
          </li>
          <li>
            <a href="/#">
              <i className="flaticon-twitter" />
            </a>
          </li>
          <li>
            <a href="/#">
              <i className="flaticon-skype" />
            </a>
          </li>
          <li>
            <a href="/#">
              <i className="flaticon-instagram" />
            </a>
          </li>
        </ul>
      </div>
      {/* Mobile Menu End */}
    </div>
  );
};

export default MobileMenu;
