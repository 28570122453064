import React from "react";

const Reviews = ({reviews}) => {
	return (
		<div
			className="tab-pane fade show active"
			id="reviews">
			{/* Tab Reviews Start */}
			<div className="tab-reviews">
				<h3 className="tab-title">Opinie naszych studentów:</h3>
				<div className="reviews-wrapper reviews-active">
					<div className="swiper-container">
						<div className="swiper-wrapper">
							{reviews.map(review => (
								<div className="single-review swiper-slide">
									<div className="review-author">
										<div className="author-thumb">
											<img
												src={review.user.profile_picture}
												alt="Author"
											/>
										</div>
									</div>
									<p>
										Lorem Ipsum has been the industry's standard dummy text since
										the 1500 when unknown printer took a galley of type and
										scrambled to make type specimen book has survived not five
										centuries but also the leap into electronic type and book.
									</p>
									<div className="author-content">
										<h4 className="name">{review.user.first_name} {review.user.last_name}</h4>
										<span className="designation">{review.user.job_title && review.user.job_title}</span>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reviews;
