import React from "react";
import about from "../../../../assets/images/about.jpg";
import logo_icon from "../../../../assets/images/logo-icon.png";
import { SingleInfoBox } from "./SingleInfoBox";

const StartACourse = () => {
  const data = [
    {
      title: "Instruktorzy",
      text1:
        "Nasi instruktorzy to eksperci w dziedzinie programowania, posiadający wieloletnie doświadczenie na komercyjnym rynku.",
      text2:
        "Oprócz wiedzy z zakresu technologii i użycia jej w projektach, mamy także wieloletnie doświadczenie w prowadzeniu kursów - stacjonarnych oraz zdalnych.",
    },
    {
      title: "Ucz się zdalnie",
      text1:
        "Korzystając z naszej platformy możesz zdalnie, niezależnie gdzie jesteś, uczyć się programowania, które pozwoli Ci wejść na rynek pracy w IT lub doszlifować wiedzę.",
      text2: "Ucz się w swoim tempie pod okiem profesjonalistów.",
    },
    {
      title: "Stały kontakt",
      text1:
        "Zakup kursu to nie tylko dostęp do lekcji, ale także komunikacja z prowadzącym, dzięki czemu nie pozostajesz sam sobie w procesie zdobywania wiedzy.",
      text2:
        "Zdobywaj feedback od nauczyciela, a nigdy nie zagubisz się w drodze do poznania nowych umiejętności.",
    },
  ];

  return (
    <div className="section">
      <div className="section-padding-02 mt-n10">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              {/* About Images Start */}
              <div className="about-images">
                <div className="images">
                  <img src={about} alt="About" />
                </div>
                <div className="about-years">
                  <div className="years-icon">
                    <img src={logo_icon} alt="About" />
                  </div>
                  <p>
                    <strong>28+</strong> Years Experience
                  </p>
                </div>
              </div>
              {/* About Images End */}
            </div>
            <div className="col-lg-6">
              {/* About Content Start */}
              <div className="about-content">
                <h2 className="main-title">
                  Przygotowaliśmy zestaw przydatnych kursów programowania, z
                  którymi zbudujesz <span>swoją przyszłość.</span>
                </h2>
                <p>
                  Posiadając ponad 15 lat doświadczenia eksperckiego w
                  programowaniu w wielu językach, dajemy Ci wiedzę niezbędną do
                  rozpoczęcia pracy w IT i rozwijania umiejętności kodowania.
                </p>
                <a href="/#" className="btn btn-primary btn-hover-dark">
                  Rozpocznij Kurs
                </a>
              </div>
              {/* About Content End */}
            </div>
          </div>
        </div>
      </div>
      <div className="section-padding-02 mt-n6">
        <div className="container">
          {/* About Items Wrapper Start */}
          <div className="about-items-wrapper">
            <div className="row">
              {data.map((element, index) => {
                return <SingleInfoBox key={index} {...element} />;
              })}
            </div>
          </div>
          {/* About Items Wrapper End */}
        </div>
      </div>
    </div>
  );
};

export default StartACourse;
