export const cartItems = [
  {
    "pk": 1,
    "starting_price": "12.00",
    "price": "12.00",
    "lectures":"29",
    "owner": {
      "first_name": "Bartosz",
      "last_name": "Kościug",
      "job_title": null,
      "profile_picture": null
    },
    "total_duration": "00:00:00",
    "title": "Data Science and Machine Learning with Python - Hands On!",
    "slug": "super_kurs",
    "level": "Beginner",
    "tag": [
      {
        "name": "tak",
        "pk": 1
      }
    ],
    "avg_rating": null,
    "count_rating": null,
    "course_type": 0,
    "start_date": null,
    "end_date": null
  },
  {
    "pk": 2,
    "starting_price": "12.00",
    "price": "12.00",
    "lectures":"29",
    "owner": {
      "first_name": "Bartosz",
      "last_name": "Kościug",
      "job_title": null,
      "profile_picture": null
    },
    "total_duration": "00:00:00",
    "title": "Data Science and Machine Learning with Python - Hands On!",
    "slug": "super_kurs",
    "level": "Beginner",
    "tag": [
      {
        "name": "tak",
        "pk": 1
      }
    ],
    "avg_rating": null,
    "count_rating": null,
    "course_type": 0,
    "start_date": null,
    "end_date": null
  },
  {
    "pk": 3,
    "starting_price": "12.00",
    "price": "12.00",
    "lectures":"29",
    "owner": {
      "first_name": "Bartosz",
      "last_name": "Kościug",
      "job_title": null,
      "profile_picture": null
    },
    "total_duration": "00:00:00",
    "title": "Data Science and Machine Learning with Python - Hands On!",
    "slug": "super_kurs",
    "level": "Beginner",
    "tag": [
      {
        "name": "tak",
        "pk": 1
      }
    ],
    "avg_rating": null,
    "count_rating": null,
    "course_type": 0,
    "start_date": null,
    "end_date": null
  },
]
