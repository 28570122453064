import React from "react";

export const CoursesTop = ({ show_title }) => {
  return (
    <div className="courses-top">
      {/* Section Title Start */}
      <div className="section-title shape-01">
        {show_title && <h2 className="main-title">Popularne kursy</h2>}
      </div>
      {/* Section Title End */}
      {/* Courses Search Start */}
      {/* <div className="courses-search"></div> */}
      {/* Courses Search End */}
    </div>
  );
};
