import React from "react";
import "../../assets/css/style.css";
import SectionHero from "../elements/Main/SectionHero";
import AllCourses from "../elements/Main/AllCourses/AllCourses";
import HowItWorks from "../elements/Main/HowItWorks/HowItWorks";
import FeedbackFromStudent from "../elements/Main/FeedbackFromStudent/FeedbackFromStudent";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../assets/css/custom.css";
const Main = () => {
  return (
    <>
      <SectionHero />
      <AllCourses show_title={true} />
      <HowItWorks />
      <FeedbackFromStudent />
      {/* <BestSupporter />
      <LatestNews /> */}
    </>
  );
};

export default Main;
