import React, { useEffect, useState } from "react";
import courseDetailsImage from "../../../assets/images/courses/courses-details.jpg";
import play from "../../../assets/images/courses/circle-shape.png";
import CourseSidebarInfo from "./CourseSidebarInfo";
import Menu from "./Menu";
import { VIDEODETAILSMENU } from "../../settings/enums";
import Description from "./Description";
import Instructors from "./Instructors";
import Reviews from "./Reviews";
import { useParams } from "react-router-dom";
import courses from "../../../services/courses.js";
import { CourseContent } from "../SingleCourse/CourseContent/CourseContent";
import { useQuery } from "react-query";
import LoadingSpinner from "../LoadingSpinner";

const CourseDetailsInfo = ({ isLoading, data }) => {
  const [menuSelect, setMenuSelect] = useState(VIDEODETAILSMENU.DESCRIPTION);
  const [courseDetails, setCourseDetails] = useState({});
  const [activeMenu, setActiveMenu] = useState({ lesson: null, chapter: null });

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="section section-padding mt-n10">
      <div className="container">
        <div className="row gx-10">
          <div className="col-lg-8">
            <div className="courses-details">
              <div
                style={{
                  width: "763px",
                  height: "396px",
                  backgroundImage: `url(${data.cover_image})`,
                  backgroundSize: "cover",
                }}
                className="courses-details-images"
              >
                {/* <img src={data.cover_image} alt="Courses Details" /> */}
                <span className="tags">{data.tag[0].name}</span>
                <div className="courses-play">
                  <img src={play} alt="Play" />
                  <a className="play video-popup" href="#">
                    <i className="flaticon-play" />
                  </a>
                </div>
              </div>
              <h2 className="title">{data.title}</h2>
              <div className="courses-details-admin">
                <div className="admin-author">
                  <div className="author-thumb">
                    <img src={data.owner.profile_picture} alt="Author" />
                  </div>
                  <div className="author-content">
                    <a className="name" href="#">
                      {data.owner.first_name} {data.owner.last_name}
                    </a>
                  </div>
                </div>
                <div className="admin-rating">
                  <span className="rating-count">{data.owner.avg_rating}</span>
                  <span className="rating-text">(5,764 Rating)</span>
                </div>
              </div>
              <div className="courses-details-tab">
                <Menu setMenuSelect={setMenuSelect} menuSelect={menuSelect} />
                <div className="details-tab-content">
                  <div className="tab-content">
                    {menuSelect === VIDEODETAILSMENU.DESCRIPTION && (
                      <Description description={data.description} />
                    )}
                    {menuSelect === VIDEODETAILSMENU.OVERVIEW && (
                      <div className="agenda-container">
                        <CourseContent
                          activeMenu={activeMenu}
                          setActiveMenu={setActiveMenu}
                          videoDeatil
                          data={data}
                        />
                      </div>
                    )}
                    {menuSelect === VIDEODETAILSMENU.INSTRUCTOR && (
                      <Instructors instructors={data.instructors} />
                    )}
                    {menuSelect === VIDEODETAILSMENU.OPINIONS && (
                      <Reviews reviews={data.students_comments} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CourseSidebarInfo data={data} />
        </div>
      </div>
    </div>
  );
};

export default CourseDetailsInfo;
