import React, { useEffect, useState } from "react";
import { CourseContent } from "../elements/SingleCourse/CourseContent/CourseContent";
import { VideoContent } from "../elements/SingleCourse/VideoContent";
import { VideoDetails } from "../elements/SingleCourse/VideoDetails/VideoDetails";
import { useQuery } from "react-query";
import courses from "../../services/courses";
import { DISPLAY_SINGLE_COURSE, SINGLECOURSEMENU } from "../settings/enums";

const SingleCourse = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["course_details", 1],
    queryFn: () => courses.getSingleCourseDetails(1),
    keepPreviousData: true,
  });
  const [activeMenu, setActiveMenu] = useState({ lesson: null, chapter: null });
  const [currentWatchingVideo, setCurrentWatchingVideo] = useState({
    lesson: null,
    chapter: null,
  });
  const [videoUrl, setVideoUrl] = useState();
  const [display, setDisplay] = useState(DISPLAY_SINGLE_COURSE.VIDEO);

  useEffect(() => {
    if (
      data &&
      currentWatchingVideo.lesson != null &&
      currentWatchingVideo.chapter != null
    ) {
      setVideoUrl(
        data.course_chapters[currentWatchingVideo.lesson].lessons[
          currentWatchingVideo.chapter
        ].video_url
      );
    }
  }, [currentWatchingVideo]);

  useEffect(() => {
    console.log(videoUrl);
  }, [videoUrl]);

  return (
    <>
      <div className="header-container-course">{/* <Header /> */}</div>
      <div className="section">
        <div className="courses-enroll-wrapper">
          <div className="courses-video-player">
            <VideoContent
              videoUrl={videoUrl}
              chapter_id={activeMenu.lesson}
              display={display}
            />
            <VideoDetails menu={SINGLECOURSEMENU} />
          </div>
          {!isLoading && (
            <CourseContent
              setCurrentWatchingVideo={setCurrentWatchingVideo}
              setVideoUrl={setVideoUrl}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              display={display}
              setDisplay={setDisplay}
              isLoading={isLoading}
              data={data}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SingleCourse;
