import React from "react";

const Instructor = () => {
  return (
    <div className="instructor">
      <div className="row">
        <div className="col-lg-4">
          <div className="enroll-tab-title">
            <h3 className="title">Instruktor</h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-10">
          <div className="enroll-tab-content">
            <div className="single-instructor">
              <div
                style={{ alignItems: "flex-start" }}
                className="review-author"
              >
                <div className="user-avatar-instructor">AL</div>
                <div className="text-wrapper-opinion">
                  <h4
                    style={{
                      color: "#2C2C2C",
                      fontSize: "22px",
                      fontFamily: "Inter",
                      fontWeight: "700",
                      lineHeight: "27px",
                    }}
                  >
                    Arkadiusz Lisiecki
                  </h4>
                  <h4>Pełniona funkcja</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been industry's
                    standard dummy text ever since the 1500s when andom unknown
                    printer took a galley of type scrambled it to make a type
                    specimen book. It has survived not’s only and five
                    centuries, but also the lea into electronic typesetting,
                    remaining priting essentially unchanged. It was popularsed
                    in the 1960 with containing Lorem Ipsum passages desktop
                    publishing software.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructor;
