import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import { useEffect } from "react";
import { mainMenu } from "../../settings/menu";
import SingleMenuElement from "./SingleMenuElement";
import authentication from "../../../services/authentication.js";
import user from "../../../services/user.js";
import { useQuery } from "react-query";
import ProfileElement from "./ProfileElement.js";
import shop_icon from "../../../assets/images/shop-icon.svg";

const Header = ({ setMenu_open }) => {
  const [scrollTop, setScrollTop] = useState(0);
  //header animation
  const handleScroll = () => {
    setScrollTop(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   const userTokens = authentication.getCurrentUser();
  //   if (userTokens) {
  //     user.getCurrentUserInfo().then((r) => {
  //       console.log(r);
  //     });
  //   }
  // }, []);

  const userTokens = authentication.getCurrentUser();
  const isLogged = Object.prototype.hasOwnProperty.call(userTokens, "refresh");

  // const { isLoading } = useQuery({
  //   queryKey: ["profile"],
  //   queryFn: () => user.getCurrentUserInfo(),
  //   keepPreviousData: true,
  //   enabled: isLogged,
  // });

  const { isLoading } = false;

  return (
    <div className="header-section" onScroll={handleScroll}>
      <div className={`header-main ${scrollTop > 100 ? "sticky" : ""}`}>
        <div className="container">
          <div className="header-main-wrapper">
            <div className="header-logo">
              <a href="/" className="header-logo-link">
                <img src={logo} alt="Logo" />
              </a>
            </div>
            <div className="header-menu d-none d-lg-block">
              <ul className="main-menu-elements nav-menu">
                {Object.keys(mainMenu).map((item, i) => (
                  <SingleMenuElement
                    key={i}
                    title={mainMenu[item].title}
                    url={mainMenu[item].url}
                    underMenu={mainMenu[item].underMenu}
                  />
                ))}
                <div style={{ width: "80px" }} />
              </ul>
            </div>
            {/* <i style={{ fontSize: "40px" }}>🛒</i> */}
            <div className="header-sign-in-up d-none d-lg-block">
              {isLogged && !isLoading ? (
                <ProfileElement />
              ) : (
                <ul>
                  <li>
                    <img
                      style={{
                        height: "29px",
                        width: "29px",
                        // marginRight: "33px",
                      }}
                      src={shop_icon}
                    />
                  </li>

                  <li>
                    <a className="sign-in" href="/login">
                      Zaloguj
                    </a>
                  </li>
                  <li>
                    <a className="sign-up" href="/registration">
                      Załóż Konto
                    </a>
                  </li>
                </ul>
              )}
            </div>

            <div
              className="header-toggle d-lg-none"
              onClick={() => setMenu_open(true)}
            >
              <a className="menu-toggle" href="/#">
                <span />
                <span />
                <span />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
