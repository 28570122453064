import React from "react";

const SingleMember = ({ rating, name, designation, image }) => {
	return (
		<div className="col">
			{/* Single Team Start */}
			<div className="single-team">
				<div className="team-thumb">
					<img
						src={image}
						alt="Author"
					/>
				</div>
				<div className="team-content">
					<div className="rating">
						<span className="count">{rating}</span>
						<i className="icofont-star" />
						<span className="text">(rating)</span>
					</div>
					<h4 className="name">{name}</h4>
					<span className="designation">{designation}</span>
				</div>
			</div>
			{/* Single Team End */}
		</div>
	);
};

export default SingleMember;
