import React, { useState } from "react";
import RegisterLoginImage from "../RegisterLoginImage";
import authentication from "../../../services/authentication";
import { REGISTERERROR } from "../../settings/enums";
import { useNavigate } from "react-router-dom";

const RegistraionBox = () => {
  const [registerInfo, setRegisterInfo] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });
  const [validateError, setValidateError] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();

  const typeInfo = (e) => {
    setRegisterInfo({ ...registerInfo, [e.target.name]: e.target.value });
    setValidateError({ ...validateError, [e.target.name]: "" });
  };

  const validateRegisterInfo = () => {
    if (registerInfo.password !== registerInfo.confirm_password) {
      setValidateError({
        ...validateError,
        confirm_password: [REGISTERERROR.NOTSAMEPASSWORDS],
      });
      return false;
    } else {
      return true;
    }
  };

  const register = (e) => {
    e.preventDefault();
    if (validateRegisterInfo()) {
      authentication.register(registerInfo).then((r) => {
        if (r.ok === true) {
          console.log("poprawnie utworzono konto");
          console.log(r);
          navigate("/login");
        } else {
          console.log("nie udało się utworzyć konta");

          r.json().then((r) => {
            console.log("r.email");
            console.log(r.email[0]);
            setValidateError({
              ...r,
            });
          });
        }
      });
    }
  };

  return (
    <div className="section section-padding">
      <div className="container">
        <div className="register-login-wrapper">
          <div className="row align-items-center">
            <RegisterLoginImage />
            <div className="col-lg-6">
              <div className="register-login-form">
                <h3 className="title">Zarejestruj się</h3>
                <div className="form-wrapper">
                  <form action="#">
                    <div className="single-form">
                      <input type="text" placeholder="Imię" />
                    </div>
                    <div className="single-form">
                      <input type="text" placeholder="Nazwisko" />
                    </div>
                    <div className="single-form">
                      <input
                        style={{
                          borderColor: `${validateError.email ? "red" : ""}`,
                        }}
                        value={registerInfo.email}
                        name="email"
                        placeholder="Email"
                        onChange={(e) => typeInfo(e)}
                      />
                      {validateError.email && (
                        <p className="error-text">{validateError.email}</p>
                      )}
                    </div>
                    <div className="single-form">
                      <input
                        style={{
                          borderColor: `${validateError.password ? "red" : ""}`,
                        }}
                        type="password"
                        placeholder="Hasło"
                        value={registerInfo.password}
                        name="password"
                        onChange={(e) => typeInfo(e)}
                      />
                      {validateError.password && (
                        <p className="error-text">{validateError.password}</p>
                      )}
                    </div>
                    <div className="single-form">
                      <input
                        style={{
                          borderColor: `${
                            validateError.confirm_password ? "red" : ""
                          }`,
                        }}
                        name="confirm_password"
                        type="password"
                        placeholder="Powtór hasło"
                        value={registerInfo.confirm_password}
                        onChange={(e) => typeInfo(e)}
                      />
                      {validateError.confirm_password && (
                        <p className="error-text">
                          {validateError.confirm_password}
                        </p>
                      )}
                    </div>

                    <div className="single-form">
                      <button
                        onClick={(e) => register(e)}
                        className="btn btn-primary btn-hover-dark w-100"
                      >
                        Zarejestruj się
                      </button>
                      <div
                        style={{ marginTop: "40px" }}
                        className="single-form"
                      >
                        <p
                          style={{
                            color: "#2C2C2C",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            letterSpacing: "-0.56px",
                          }}
                        >
                          Masz już konto?{" "}
                          <a style={{ textDecoration: "underline" }} href="#">
                            Zaloguj się
                          </a>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistraionBox;
