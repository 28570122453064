import authentication from "../services/authentication.js";

export async function handleResponse(response, requestOptions) {
	if (!response.ok) {
		if ([401, 403].indexOf(response.status) !== -1) {
			const clonedResponse = response.clone();
			const body = await clonedResponse.json();
			console.log(body);
			return authentication.getRefleshToken().then((r) => {
				if (r && r.access) {
					return fetch(response.url, {
						...requestOptions,
						headers: {
							...requestOptions.headers,
							Authorization: `Bearer ${r.access}`,
						},
					});
				}
			});
		}
	}
	return response;
}
