import { Suspense } from "react";
import React from "react";
import PreloaderComponent from "../Utils/PreloaderComponent";
import PropTypes from "prop-types";

const RouteWrapper = ({ children }) => {
	return <Suspense fallback={<PreloaderComponent />}>{children}</Suspense>;
};

RouteWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

export default RouteWrapper;
