import React from "react";
import AverageBox from "./AverageBox";

const Achievements = () => {
  return (
    <div className="">
      <h3>Moje osiągnięcia</h3>
      <div style={{ marginBottom: "40px" }} className="row align-items-center">
        <div className="col-lg-3" />
        <div className="col-lg-6">
          <h2>Średnia z testów</h2>
          <div className="medals-wrapper">
            <AverageBox brown />
            <AverageBox silver />
            <AverageBox gold />
          </div>
        </div>
        <div className="col-lg-3" />
      </div>
      <div style={{ marginBottom: "40px" }} className="row align-items-center">
        <div className="col-lg-3" />
        <div className="col-lg-6">
          <h2>Ukończenie kursu jako pierwszy</h2>
          <div style={{ justifyContent: "center" }} className="medals-wrapper">
            <AverageBox asFirst />
          </div>
        </div>
        <div className="col-lg-3" />
      </div>
      <div style={{ marginBottom: "40px" }} className="row align-items-center">
        <div className="col-lg-3" />
        <div className="col-lg-6">
          <h2>Ukończenie trzech kursów</h2>
          <div className="medals-wrapper">
            <AverageBox threeCourses />
            <AverageBox threeCourses />
            <AverageBox threeCourses />
          </div>
        </div>
        <div className="col-lg-3" />
      </div>
    </div>
  );
};

{
  /* <div className="col-lg-6"></div>; */
}

export default Achievements;
