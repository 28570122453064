import React from "react";

export const SingleInfoBox = ({ title, text1, text2 }) => {
  return (
    <div className="col-lg-4">
      <div className="about-item">
        <div className="item-icon-title">
          <div className="item-icon">
            <i className="flaticon-tutor" />
          </div>
          <div className="item-title">
            <h3 className="title">{title}</h3>
          </div>
        </div>
        <p>{text1 ? text1 : "Lorem text nanan "}</p>
        <p>{text2 ? text2 : "Lorem text nanan "}</p>
      </div>
    </div>
  );
};
