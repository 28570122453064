import React, { useContext, useState } from "react";
import example_image_course from "../../../assets/images/courses/courses-01.jpg";
import { CartContext } from "../../../App";

const ShopPreview = () => {
  const { cart, handleSetCart } = useContext(CartContext);
  return (
    <div id="notification-menu" className="notification-menu ">
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          color: "#2C2C2C",
          fontSize: "20px",
          fontFamily: "Inter",
          fontWeight: "500",
          lineHeight: "26.4px",
        }}
      >
        {cart.length === 0 ? (
          <li style={{ marginBottom: "25px" }}>Twój koszyk jest pusty</li>
        ) : (
          cart.map((product, index) => {
            return (
              <li
                key={index}
                style={{
                  marginRight: 0,
                  padding: "19px",
                  width: "100%",
                }}
                className="preview-element-wrapper"
              >
                <div className="image-previev-wrapper">
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                    src={product.cover_image}
                    alt="zdjecie kursu"
                  />
                </div>
                <div className="text-previev-wrapper">
                  <h6
                    style={{ marginBottom: 0 }}
                    className="text-previev-title"
                  >
                    {product.title}
                  </h6>
                  <h6
                    style={{ marginBottom: 0 }}
                    className="text-previev-author"
                  >
                    {product.owner.first_name} {product.owner.last_name}
                  </h6>
                  <div className="preview-item-price">
                    <h6
                      style={{ marginBottom: 0 }}
                      className="text-previev-price"
                    >
                      {product.price}zł
                    </h6>
                  </div>
                </div>
              </li>
            );
          })
        )}
        {cart.length !== 0 && (
          <li
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "16px",
              padding: "0 0 27px 19px",
            }}
          >
            <h6 className="summary-price-preview">RAZEM: {cart
              .reduce(
                (sum, item) =>
                  sum + parseFloat(item.price.replace(",", ".")),
                0
              )
              .toFixed(2)} zł</h6>
            <a className="go-to-shop-cart-button" href="/paymentcart">
              Przejdź do koszyka
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ShopPreview;
