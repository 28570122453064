import React, { useState } from "react";
import CoursesMenu from "./Courses_Menu/CoursesMenu";
import { CoursesTop } from "./Courses_Top/CoursesTop";
import { SingleCourse } from "./Single_Course/Single_Course";

import example_image_course from "../../../../assets/images/courses/courses-03.jpg";
import example_image_author from "../../../../assets/images/author/author-01.jpg";
import courses from "../../../../services/courses.js";
import LoadingSpinner from "../../LoadingSpinner.js";
import { useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const All_Courses = ({ show_title, marginBottom }) => {
	const [selectedTag, setSelectedTag] = useState();
	const [page, setPage] = useState(1);

	const { isLoading, data } = useQuery({
		queryKey: ["all_courses", page],
		queryFn: () => courses.getCoursesList(page),
		keepPreviousData: true,
	});

	return (
		<div
			className="section section-padding-02"
			style={{ marginBottom: `${marginBottom ? "80px" : ""}` }}>
			<div className="container">
				<CoursesTop show_title={show_title} />
				<CoursesMenu
					setSelectedTag={setSelectedTag}
					selectedTag={selectedTag}
				/>
				<div className="tab-content courses-tab-content">
					<div
						className="tab-pane fade show active"
						id="tabs1">
						<div className="courses-wrapper">
							<div className="row">
								{isLoading ? (
									<LoadingSpinner />
								) : (
									data.results.map((course, index) => {
										return (
											<SingleCourse
												pk={course.pk}
												key={index}
												course_image={course.cover_image}
												author_image={course.owner.profile_picture}
												author_name={`${course.owner.first_name} ${course.owner.last_name}`}
												category={course.tag[0].name}
												course_title={course.title}
												course_length={course.total_duration}
												lectures_number={course.lessons_count}
												price={course.price}
												rating={4.9}
											/>
										);
									})
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="courses-btn text-center">
					{!isLoading && data.results.length > 12 && (
						<>
							<button
								disabled={page === 1}
								onClick={() => setPage((old) => Math.max(old - 1, 1))}>
								previous page
							</button>
							<span>PAGE {page}</span>
							<button
								disabled={!data.next}
								onClick={() => setPage((old) => old + 1)}>
								next page
							</button>
						</>
					)}
				</div>
			</div>
			<ReactQueryDevtools initialIsOpen={false} />
		</div>
	);
};

export default All_Courses;
