import { useNavigate } from "react-router-dom";

export const SingleCourse = ({
  course_image,
  author_image,
  author_name,
  category,
  course_title,
  course_length,
  lectures_number,
  price,
  discount,
  rating,
  pk,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/coursedetails/${pk}`)}
      className="col-lg-4 col-md-6 focusable-course"
      tabIndex="0"
      aria-label="Podstrona kursu"
    >
      <div className="single-courses">
        <div
          style={{
            width: "328px",
            height: "198px",
            borderRadius: "15px",
            backgroundImage: `url(${course_image})`,
            backgroundSize: "cover",
          }}
          className="courses-images"
        >
          {/* <a>
						<img
							src={course_image}
							alt="Courses"
						/>
					</a> */}
        </div>
        <div className="courses-content">
          <div className="courses-author">
            <div className="author">
              <div className="author-thumb">
                <a>
                  <img src={author_image} alt="Author" />
                </a>
              </div>
              <div className="author-name">
                <a className="name">{author_name}</a>
              </div>
            </div>
            <div className="tag">
              <a>{category}</a>
            </div>
          </div>
          <h4 className="title">
            <a>{course_title}</a>
          </h4>
          <div className="courses-meta">
            <span>
              {" "}
              <i className="icofont-clock-time" /> {course_length}
            </span>
            <span>
              {" "}
              <i className="icofont-read-book" /> {lectures_number} lekcji/e{" "}
            </span>
          </div>
          <div className="courses-price-review">
            <div className="courses-price">
              <span className="sale-parice">{price}zł</span>
            </div>
            <div className="courses-review">
              <span className="rating-count">Ocena {rating}/5</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
