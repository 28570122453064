const RoutesPaths = {
  Main: {
    path: "/",
    route: () => "/",
  },
  Profile: {
    path: "/profile",
    route: () => "/",
  },
  About: {
    path: "/about",
    route: () => "/",
  },
  Login: {
    path: "/login",
    route: () => "/",
  },
  Registration: {
    path: "/registration",
    route: () => "/",
  },
  Courses: {
    path: "/courses",
    route: () => "/",
  },
  SingleCourse: {
    path: "/singlecourse",
    route: () => "/",
  },
  CourseDetails: {
    path: "/coursedetails/:courseId",
    route: () => "/",
  },
  MyCourses: {
    path: "/mycourses",
    route: () => "/",
  },
  PaymentCard: {
    path: "/paymentcart",
    route: () => "/",
  },
  Contact: {
    path: "/contact",
    route: () => "/",
  },
  PasswordReset: {
    path: "/api/user/confirm-password-reset",
    route: () => "/",
  },
  Privacy: {
    path: "/privacy",
    route: () => "/",
  },
  Terms: {
    path: "/terms",
    route: () => "/",
  },
  ConfirmAccount: {
    path: "/api/user/activate/",
    route: () => "/",
  },
  Messages: {
    path: "/messages",
    route: () => "/",
  },
  LiveCourse: {
    path: "/liveCourse/:courseId",
    route: () => "/",
  },
};

export default RoutesPaths;
