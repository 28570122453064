import React from "react";

const Instructors = ({instructors}) => {
	return (
		<div
			className="tab-pane fade show active"
			id="instructors">
			{/* Tab Instructors Start */}
			<div className="tab-instructors">
				<h3 className="tab-title">Instruktorzy kursu:</h3>
				<div className="row">
					{instructors.map(instructor => (
						<div className="col-md-3 col-6">
							{/* Single Team Start */}
							<div className="single-team">
								<div className="team-thumb">
									<img
										src={instructor.profile_picture}
										alt="Author"
									/>
								</div>
								<div className="team-content">
									<h4 className="name">{instructor.first_name} {instructor.last_name}</h4>
									<span className="designation">{instructor.job_title}</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Instructors;
