import React from "react";

// const OverviewTable = ({instructor, duration, lectures, level, language, captions}) => {
const OverviewTable = () => {
	return (
		<table className="table">
			<tbody>
				<tr>
					<th>
						Instructor <span>:</span>
					</th>
					<td>Pamela Foster</td>
				</tr>
				<tr>
					<th>
						Duration <span>:</span>
					</th>
					<td>08 hr 15 mins</td>
				</tr>
				<tr>
					<th>
						Lectures <span>:</span>
					</th>
					<td>2,16</td>
				</tr>
				<tr>
					<th>
						Level <span>:</span>
					</th>
					<td>Secondary</td>
				</tr>
				<tr>
					<th>
						Language <span>:</span>
					</th>
					<td>English</td>
				</tr>
				<tr>
					<th>
						Caption’s <span>:</span>
					</th>
					<td>Yes</td>
				</tr>
			</tbody>
		</table>
	);
};

export default OverviewTable;
