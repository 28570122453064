import React from "react";
import { useNavigate } from "react-router-dom";

const SingleCourse = ({
  courseImage,
  authorImage,
  author,
  title,
  complete,
  category,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/singlecourse`)}
      className="col-lg-4 col-md-6"
    >
      <div className="single-courses">
        <div
          style={{
            width: "328px",
            height: "198px",
            borderRadius: "15px",
            backgroundImage: `url(${courseImage})`,
            backgroundSize: "cover",
          }}
          className="courses-images"
        >
          <div className="courses-option dropdown">
            <button
              className="option-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span />
              <span />
              <span />
            </button>
            <ul className="dropdown-menu">
              <li>
                <a href="#">
                  <i className="icofont-share-alt" /> Share
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="icofont-plus" /> Create Collection
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="icofont-star" /> Favorite
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="icofont-archive" /> Archive
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="courses-content">
          <div className="courses-author">
            <div className="author">
              <div className="author-thumb">
                <a>
                  <img src={authorImage} alt="Author" />
                </a>
              </div>
              <div className="author-name">
                <a className="name">
                  {author.first_name} {author.last_name}
                </a>
              </div>
            </div>
            <div className="tag">
              <a>{category}</a>
            </div>
          </div>
          <h4 className="title">
            <a href="courses-details.html">{title}</a>
          </h4>
          <div className="courses-rating">
            <p>{complete}% Complete</p>
            <div className="rating-progress-bar">
              <div className="rating-line" style={{ width: `${complete}%` }} />
            </div>
            <div className="rating-meta">
              <span className="rating-star">
                <span className="rating-bar" style={{ width: "80%" }} />
              </span>
              <p>Your rating</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCourse;
