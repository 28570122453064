import shape_7 from "../../../assets/images/shape/shape-7.png";
import slider_1 from "../../../assets/images/slider/slider-1.png";

const SectionHero = () => {
  return (
    <div className="section slider-section">
      {/* Slider Shape Start */}
      {/* Slider Shape End */}
      <div className="container">
        {/* Slider Content Start */}
        <div className="slider-content">
          <h4 className="sub-title">Rozpocznij naukę razem z nami</h4>
          <h2 className="main-title">
            Zapisz się na kurs lub skorzystaj z gotowych lekcji
            <span> programowania.</span>
          </h2>
          <p>
            Zarejestruj się, aby uzyskać dostęp do nowych możliwości nauki
            kodowania.
          </p>
          <a className="btn btn-primary btn-hover-dark" href="/#">
            Start A Course
          </a>
        </div>
        {/* Slider Content End */}
      </div>
      {/* Slider Courses Box Start */}
      {/* Slider Courses Box End */}
      {/* Slider Rating Box Start */}
      <div className="slider-rating-box">
        <img className="shape animation-up" src={shape_7} alt="Shape" />
      </div>
      {/* Slider Rating Box End */}
      {/* Slider Images Start */}
      <div className="slider-images">
        <div className="images">
          <img src={slider_1} alt="Slider" />
        </div>
      </div>
      {/* Slider Images End */}
      {/* Slider Video Start */}
      {/* Slider Video End */}
    </div>
  );
};

export default SectionHero;
