import config from "../config";
import authHeader from "../helper/auth-header.js";
import { handleResponse } from "../helper/handle-response.js";

const getCoursesList = async (page) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${config.apiUrl}/courses/list/?page=${page}`,
    requestOptions
  ).then((r) => {
    if (r.ok) {
      return r.json();
    }
  });
};

const getSingleCourseDetails = async (courseId) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${config.apiUrl}/courses/${courseId}`, requestOptions).then(
    (r) => {
      console.log(r);
      if (r.ok) {
        return r.json();
      }
    }
  );
};

const getCurrentUserCoursesList = async (page) => {
  const auth = authHeader();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth },
  };

  return fetch(
    `${config.apiUrl}/courses/my_courses/?page=${page}`,
    requestOptions
  ).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getCoursesTags = async () => {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`${config.apiUrl}/courses/tags/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

//pobiera pytasnie oraz wyswietlane odpowiedzi do quizu
const getQuizData = async (chapter_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth },
  };

  return fetch(
    `${config.apiUrl}/courses/chapter-test/${chapter_id}`,
    requestOptions
  ).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default {
  getCoursesList,
  getQuizData,
  getSingleCourseDetails,
  getCurrentUserCoursesList,
  getCoursesTags,
};
