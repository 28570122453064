import React, { useEffect } from "react";
import { DISPLAY_SINGLE_COURSE } from "../../../settings/enums";

const SingleLink = ({
  setCurrentWatchingVideo,
  activeMenu,
  setActiveMenu,
  title,
  length,
  setDisplay,
  displayStage,
  chapterNumber,
}) => {
  const setVideoDisplay = () => {
    if (displayStage && setDisplay) {
      switch (displayStage) {
        case DISPLAY_SINGLE_COURSE.QUIZ:
          setDisplay(DISPLAY_SINGLE_COURSE.QUIZ);
          break;
      }
    }
    if (setActiveMenu) {
      setDisplay(DISPLAY_SINGLE_COURSE.VIDEO);
      setActiveMenu({ ...activeMenu, chapter: chapterNumber });
      setCurrentWatchingVideo({ ...activeMenu, chapter: chapterNumber });
    }
  };
  return (
    <a
      onClick={() => setVideoDisplay()}
      className={`link ${
        chapterNumber == activeMenu?.chapter &&
        displayStage !== DISPLAY_SINGLE_COURSE.QUIZ
          ? "active"
          : ""
      }`}
      href="#"
    >
      <p>{title}</p>
      <span className="total-duration">{length}</span>
    </a>
  );
};

export default SingleLink;
