import React from "react";
import exampleUserMessageAvatar from "../../../assets/images/examplemessageuser.png";

const MessagesBox = () => {
  return (
    <div className="section section-padding-02">
      <div className="container">
        <div style={{ paddingLeft: 0 }} className="main-content-wrapper pb-0">
          <div className="container-fluid">
            {/* Admin Top Bar End */}
            {/* Question & Answer End */}
            <div className="question-answer">
              <div className="row">
                <div className="col-xl-4">
                  {/* Answer User List Start */}
                  <div className="answer-user-list">
                    {/* Single User Start */}
                    <div className="single-user active">
                      <div className="user-author">
                        <img src={exampleUserMessageAvatar} alt="Author" />
                      </div>
                      <div className="user-content">
                        <h6 className="name">Natosha Sibley</h6>
                        <p>Woolentor and woocommerce</p>
                      </div>
                      <span className="time">02 days ago</span>
                    </div>
                    {/* Single User End */}
                    {/* Single User Start */}
                    <div className="single-user">
                      <div className="user-author">
                        <img src={exampleUserMessageAvatar} alt="Author" />
                      </div>
                      <div className="user-content">
                        <h6 className="name">Alison Hunter</h6>
                        <p>Woolentor and woocommerce</p>
                      </div>
                      <span className="time">02 days ago</span>
                    </div>
                    {/* Single User End */}
                    {/* Single User Start */}
                    <div className="single-user">
                      <div className="user-author">
                        <img src={exampleUserMessageAvatar} alt="Author" />
                      </div>
                      <div className="user-content">
                        <h6 className="name">Mabel Alvarez</h6>
                        <p>Woolentor and woocommerce</p>
                      </div>
                      <span className="time">02 days ago</span>
                    </div>
                    {/* Single User End */}
                    {/* Single User Start */}
                    <div className="single-user">
                      <div className="user-author">
                        <img src={exampleUserMessageAvatar} alt="Author" />
                      </div>
                      <div className="user-content">
                        <h6 className="name">Preston Farmer</h6>
                        <p>Woolentor and woocommerce</p>
                      </div>
                      <span className="time">02 days ago</span>
                    </div>
                    {/* Single User End */}
                  </div>
                  {/* Answer User List End */}
                </div>
                <div className="col-xl-8">
                  {/* Answer Message Wrapper Start */}
                  <div className="answer-message-wrapper">
                    <ul>
                      <li>
                        {/* Single Message Start */}
                        <div className="single-message">
                          <div className="message-author">
                            <div className="author-images">
                              <img
                                src={exampleUserMessageAvatar}
                                alt="Author"
                              />
                            </div>
                            <div className="author-content">
                              <h6 className="name">
                                <strong>Natosha Sibley</strong>{" "}
                              </h6>
                            </div>
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s
                            when an unknown printer took a galley of type and
                            scrambled it to make specimen book has survived not
                            only five centuries.
                          </p>
                        </div>
                        {/* Single Message End */}
                        <ul className="message-replay">
                          <li>
                            {/* Single Message Start */}
                            <div className="single-message">
                              <div className="message-author">
                                <div className="author-images">
                                  <img
                                    src={exampleUserMessageAvatar}
                                    alt="Author"
                                  />
                                </div>
                                <div className="author-content">
                                  <h6 className="name">
                                    <strong>Ashley Reeves</strong>{" "}
                                    <span className="instructor">
                                      Instructor
                                    </span>
                                  </h6>
                                  <span className="time">
                                    Asked: March 28, 2021
                                  </span>
                                </div>
                                <div className="meta">
                                  <a className="answer" href="#">
                                    <i className="icofont-ui-messaging" />{" "}
                                    Answer
                                  </a>
                                </div>
                              </div>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s when an unknown printer took a galley
                                of type and scrambled it to make specimen book
                                has survived not only five centuries.
                              </p>
                            </div>
                            {/* Single Message End */}
                          </li>
                        </ul>
                      </li>
                      <li>
                        {/* Single Message Start */}
                        <div className="single-message">
                          <div className="message-author">
                            <div className="author-images">
                              <img
                                src={exampleUserMessageAvatar}
                                alt="Author"
                              />
                            </div>
                            <div className="author-content">
                              <h6 className="name">
                                <strong>Natosha Sibley</strong>
                              </h6>
                              <span className="time">
                                Asked: March 28, 2021
                              </span>
                            </div>
                            <div className="meta">
                              <a className="answer" href="#">
                                <i className="icofont-ui-messaging" /> Answer
                              </a>
                            </div>
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s
                            when an unknown printer took a galley of type and
                            scrambled it to make specimen book has survived not
                            only five centuries.
                          </p>
                        </div>
                        {/* Single Message End */}
                      </li>
                    </ul>
                    <a className="loadmore" href="#">
                      Loard more 22 answer
                    </a>
                  </div>
                  {/* Answer Message Wrapper End */}
                </div>
              </div>
            </div>
            {/* Question & Answer End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesBox;
