import React, { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import authentication from "../../services/authentication.js";
import { useNavigate } from "react-router-dom";

const TYPE_ENUM = {
  EMAIL: "email",
  NEW_PASSWORD: "new_password",
};

const PasswordReset = () => {
  const [confirm, setConfirm] = useState(false);
  const [type, setType] = useState();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [resetPasswordData, setResetPasswordData] = useState();
  const [repeatPassword, setRepeatPassword] = useState("");
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();

  useEffect(() => {
    const uid = searchParams.get("uid");
    const token = searchParams.get("token");
    const typeView = searchParams.get("type");
    if (uid && token) {
      setResetPasswordData({
        uid: Number(uid),
        token: token,
        new_password: "",
      });
    }
    if (typeView) {
      setType(typeView);
    }
  }, [searchParams]);

  const sendEmail = async () => {
    authentication.passwordResetSendEmail(email).then((r) => {
      console.log("err");
      if (r.ok) {
        setConfirm(true);
      } else {
        r.json().then((r) => {
          if (r.email) {
            setError(r.email[0]);
          }
        });
      }
    });
  };

  const sendNewPassword = async (e) => {
    e.preventDefault();
    if (resetPasswordData.new_password !== repeatPassword) {
      setError("Podane hasła nie są takie same");
    } else {
      authentication.sendPasswordReset(resetPasswordData).then((r) => {
        if (r.status == 200) {
          navigate("/login");
        }
      });
    }
  };

  return (
    <>
      <main className="password-reset-wrapper">
        {type == TYPE_ENUM.EMAIL && !confirm && (
          <div className="password-reset-box">
            <h6 className="password-reset-title">Resetowanie hasła</h6>
            <h6 className="password-reset-text">
              Wprowadź adres e-mail powiązany z Twoim kontem, a wyślemy Ci
              instrukcje do resetowania hasła.
            </h6>
            <input
              style={error ? { borderColor: "#B2282D" } : {}}
              value={email}
              onChange={(e) => {
                setError("");
                setEmail(e.target.value);
                setConfirm(false);
              }}
              type="email"
              className="password-reset-input"
              placeholder="email"
            />
            {error && (
              <p style={{ marginTop: "-20px" }} className="error-text">
                {error}
              </p>
            )}
            <button
              onClick={() => sendEmail()}
              className="password-reset-button"
            >
              Wyślij
            </button>
          </div>
        )}
        {confirm && (
          <div className="password-reset-box">
            <h6 className="password-reset-title">Prośba wysłana</h6>
            <h6 className="password-reset-text">
              Wiadomość email zawierająca instrukcje została wysłana.
            </h6>
            <button className="password-reset-button">ok</button>
          </div>
        )}
        {resetPasswordData && !confirm && (
          <div className="password-reset-box">
            <h6 className="password-reset-title">Hasło zostało zresetowane</h6>
            <h6 className="password-reset-text">
              Ze względów bezpieczeństwa prosimy o podania nowego hasła.
            </h6>
            <input
              style={error ? { borderColor: "#B2282D" } : {}}
              onChange={(e) => {
                setError("");
                setResetPasswordData({
                  ...resetPasswordData,
                  new_password: e.target.value,
                });
              }}
              value={resetPasswordData.new_password}
              type="password"
              className="password-reset-input"
              placeholder="Nowe hasło"
            />
            <input
              style={error ? { borderColor: "#B2282D" } : {}}
              onChange={(e) => {
                setError("");
                setRepeatPassword(e.target.value);
              }}
              value={repeatPassword}
              type="password"
              className="password-reset-input"
              placeholder="Powtórz nowe hasło"
            />
            {error && (
              <p style={{ marginTop: "-20px" }} className="error-text">
                {error}
              </p>
            )}
            <button
              onClick={(e) => sendNewPassword(e)}
              className="password-reset-button"
            >
              Zmień hasło
            </button>
          </div>
        )}
      </main>
    </>
  );
};

export default PasswordReset;
