import React from "react";
import RoutesPaths from "./RoutesPaths";

import Login from "./components/pages/Login";
import Courses from "./components/pages/Courses";
import About from "./components/pages/About";
import Registration from "./components/pages/Registration";
import Main from "./components/pages/Main";
import SingleCourse from "./components/pages/SingleCourse";
import CourseDetails from "./components/pages/CourseDetails";
import MyCourses from "./components/pages/MyCourses";
import PaymentCart from "./components/pages/PaymentCart.js";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import Profile from "./components/pages/Profile.js";
import { Contact } from "./components/pages/Contact.js";
import PasswordReset from "./components/pages/PasswordReset";
import ConfirmAccount from "./components/pages/ConfirmAccount";
import Messages from "./components/pages/Messages";
import LiveCourse from "./components/pages/LiveCourse";

// const Login = React.lazy(() => import("./components/pages/Login"));
// const Courses = React.lazy(() => import("./components/pages/Courses"));
// const About = React.lazy(() => import("./components/pages/About"));
// const Registration = React.lazy(() =>
// 	import("./components/pages/Registration")
// );
// const SingleCourse = React.lazy(() =>
// 	import("./components/pages/SingleCourse")
// );
// const Main = React.lazy(() => import("./components/pages/Main"));

const routerList = [
  {
    path: RoutesPaths.Main.path,
    component: <Main />,
  },
  {
    path: RoutesPaths.Profile.path,
    component: <Profile />,
  },
  {
    path: RoutesPaths.PaymentCard.path,
    component: <PaymentCart />,
  },
  {
    path: RoutesPaths.SingleCourse.path,
    component: <SingleCourse />,
  },
  {
    path: RoutesPaths.Registration.path,
    component: <Registration />,
  },
  {
    path: RoutesPaths.About.path,
    component: <About />,
  },
  {
    path: RoutesPaths.Login.path,
    component: <Login />,
  },
  {
    path: RoutesPaths.Courses.path,
    component: <Courses />,
  },
  {
    path: RoutesPaths.CourseDetails.path,
    component: <CourseDetails />,
  },
  {
    path: RoutesPaths.MyCourses.path,
    component: <MyCourses />,
  },
  {
    path: RoutesPaths.Contact.path,
    component: <Contact />,
  },
  {
    path: RoutesPaths.PasswordReset.path,
    component: <PasswordReset />,
  },
  {
    path: RoutesPaths.Privacy.path,
    component: <Privacy />,
  },
  {
    path: RoutesPaths.Terms.path,
    component: <Terms />,
  },
  {
    path: RoutesPaths.ConfirmAccount.path,
    component: <ConfirmAccount />,
  },
  {
    path: RoutesPaths.Messages.path,
    component: <Messages />,
  },
  {
    path: RoutesPaths.LiveCourse.path,
    component: <LiveCourse />,
  },
];

export default routerList;
