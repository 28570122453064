import React, { useState } from "react";
import {
  LIVECOURSEMENU,
  SINGLECOURSEMENU,
  VIDEODETAILSMENU,
} from "../../../settings/enums";
import Menu from "./Menu";
import OverviewTable from "./OverviewTable";
import SingleInstructor from "./SingleInstructor";
import Opinion from "./Opinion";
import Instructor from "./Instructor";
import Forum from "../../Forum/Forum";

export const VideoDetails = ({ menu }) => {
  const [tab, setTab] = useState(VIDEODETAILSMENU.DESCRIPTION);

  return (
    <div className="courses-enroll-content">
      <div className="courses-enroll-title">
        <h2 className="title">
          Finance &amp; Investment Series: Learn to Budget and Calculate Your
          Net Worth.
        </h2>
      </div>
      <div className="courses-enroll-tab">
        <Menu menu={menu} tab={tab} setTab={setTab} />
      </div>
      <div className="courses-enroll-tab-content">
        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              tab == SINGLECOURSEMENU.DESCRIPTION ||
              tab == LIVECOURSEMENU.DESCRIPTION
                ? "show active"
                : ""
            }`}
            id="tab1"
          >
            <div className="overview">
              <div className="row">
                <div className="col-lg-4">
                  <div className="enroll-tab-title">
                    <h3 className="title">Opis kursu</h3>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="enroll-tab-content">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been industry's
                      standard dummy text ever since the 1500s when andom
                      unknown printer took a galley of type scrambled it to make
                      a type specimen book. It has survived not’s only and five
                      centuries, but also the lea into electronic typesetting,
                      remaining priting essentially unchanged. It was popularsed
                      in the 1960 with containing Lorem Ipsum passages desktop
                      publishing software.
                    </p>
                    {<OverviewTable />}
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been industry's
                      standard dummy text ever since the 1500s when andom
                      unknown printer took a galley of type scrambled it to make
                      a type specimen book.
                    </p>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been industry's
                      standard dummy text ever since the 1500s when andom
                      unknown printer took a galley of type scrambled it to make
                      a type specimen book.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              tab === VIDEODETAILSMENU.DESCRIPTION ? "show active" : ""
            }`}
            id="tab2"
          >
            <div className="description">
              <div className="row">
                <div className="col-lg-4">
                  <div className="enroll-tab-title">
                    <h3 className="title">Opis kursu</h3>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="enroll-tab-content">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been industry's
                      standard dummy text ever since the 1500s when andom
                      unknown printer took a galley of type scrambled it to make
                      a type specimen book. It has survived not’s only and five
                      centuries, but also the lea into electronic typesetting,
                      remaining priting essentially unchanged. It was popularsed
                      in the 1960 with containing Lorem Ipsum passages desktop
                      publishing software.
                    </p>
                    <p className="text">
                      “Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has industry's standard
                      dummy text ever since the 1500s when andom unknown printer
                      took a galley scrambled it to make a type specimen book.”
                    </p>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been industry's
                      standard dummy text ever since the 1500s when andom
                      unknown printer took a galley of type scrambled it to make
                      a type specimen book. It has survived not’s only and five
                      centuries, but also the lea into electronic typesetting,
                      remaining priting essentially unchanged. It was popularsed
                      in the 1960 with containing Lorem Ipsum passages desktop
                      publishing software.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              tab === LIVECOURSEMENU.FORUM ? "show active" : ""
            }`}
            id="tab5"
          >
            <Forum />
          </div>
          <div
            className={`tab-pane fade ${
              tab === VIDEODETAILSMENU.INSTRUCTOR ? "show active" : ""
            }`}
            id="tab4"
          >
            <Instructor />
          </div>
          <div
            className={`tab-pane fade ${
              tab === VIDEODETAILSMENU.OPINIONS ? "show active" : ""
            }`}
            id="tab4"
          >
            <Opinion />
          </div>
        </div>
      </div>
    </div>
  );
};
