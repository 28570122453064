import React, { useState } from "react";
import Header from "../elements/Headers/Header.js";
import SectionHero from "../elements/SectionHero.js";
import LoadingSpinner from "../../components/elements/LoadingSpinner.js";
import Achievements from "../elements/Profile/Achievements.js";

const Profile = () => {
  //com
  const [typedData, setTypedData] = useState({
    name: "",
    bio: "",
    github_username: "",
    job_title: "",
    linkedin_username: "",
    location: "",
    personal_website: "",
    profile_picture: "",
    surname: "",
    twitter_username: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <SectionHero
        page_name={"profile"}
        description={"Załóż "}
        description_light={"konto."}
      />
      <div className="section section-padding">
        <div className="container">
          <Achievements />
        </div>
      </div>
    </>
  );
};

export default Profile;
