import React from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="section footer-section">
        <div className="footer-widget-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 order-md-1 order-lg-1">
                {/* Footer Widget Start */}
                <div className="footer-widget">
                  <div className="widget-logo">
                    <a href="/">
                      <img src={logo} alt="Logo" />
                    </a>
                  </div>
                  <div className="text-wrapper-footer">
                    <p className="text-footer">
                      Nasza platforma z kursami to wyjątkowe miejsce, w którym
                      pod okiem ekspertów zdobędziesz nowe umiejętności. Otwórz
                      sie na nowe perspektywy kariery w IT i zapisz się na kurs
                      już teraz!
                    </p>
                  </div>
                  {/* <div className="widget-address">
                    <h4 className="footer-widget-title">Caribbean Ct</h4>
                    <p>Haymarket, Virginia (VA).</p>
                  </div>
                  <ul className="widget-info">
                    <li>
                      <p>
                        {" "}
                        <i className="flaticon-email" />{" "}
                        <a href="mailto:address@gmail.com">address@gmail.com</a>{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        <i className="flaticon-phone-call" />{" "}
                        <a href="tel:9702621413">(970) 262-1413</a>{" "}
                      </p>
                    </li>
                  </ul>
                  <ul className="widget-social">
                    <li>
                      <a href="/#">
                        <i className="flaticon-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="flaticon-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="flaticon-skype" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="flaticon-instagram" />
                      </a>
                    </li>
                  </ul> */}
                </div>
                {/* Footer Widget End */}
              </div>
              <div className="col-lg-6 order-md-3 order-lg-2">
                {/* Footer Widget Link Start */}
                <div className="footer-widget-link">
                  {/* Footer Widget Start */}
                  <div className="footer-widget">
                    <h4 className="footer-widget-title">Firma</h4>
                    <ul className="widget-link">
                      <li>
                        <a href="/#">Polityka Prywatności</a>
                      </li>
                      <li>
                        <a href="/#">Regulamin</a>
                      </li>
                      <li>
                        <a
                          onClick={(e) => {
                            e.preventDefault();

                            navigate(`/about`);
                          }}
                          href="/about"
                        >
                          O nas
                        </a>
                      </li>
                      <li>
                        <a href="/#">Kontakt</a>
                      </li>
                    </ul>
                  </div>
                  {/* Footer Widget End */}
                  {/* Footer Widget Start */}
                  <div className="footer-widget">
                    <h4 className="footer-widget-title">
                      Projekt dofinansowany
                    </h4>
                    <ul className="widget-link">
                      <li>
                        <a href="/#">
                          Projekt dofinansowany ze <br /> środków Unii
                          Europejskiej
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Footer Widget End */}
                </div>
                {/* Footer Widget Link End */}
              </div>
              <div className="col-lg-3 col-md-6 order-md-2 order-lg-3">
                {/* Footer Widget Start */}
                {/* Footer Widget End */}
              </div>
            </div>
          </div>
        </div>
        {/* Footer Widget Section End */}
        {/* Footer Copyright Start */}
        <div className="footer-copyright">
          <div className="container">
            {/* Footer Copyright Start */}
            <div className="copyright-wrapper">
              <div className="copyright-link">
                <a href="/#">Terms of Service</a>
                <a href="/#">Privacy Policy</a>
                <a href="/#">Sitemap</a>
                <a href="/#">Security</a>
              </div>
              <div className="copyright-text">
                <p>
                  © 2021 <span> Edule </span> Made with{" "}
                  <i className="icofont-heart-alt" /> by{" "}
                  <a href="/#">Codecarnival</a>
                </p>
              </div>
            </div>
            {/* Footer Copyright End */}
          </div>
        </div>
        {/* Footer Copyright End */}
      </div>
      {/* Footer End */}
      {/*Back To Start*/}
      <a href="/#" className="back-to-top">
        <i className="icofont-simple-up" />
      </a>
    </>
  );
};
