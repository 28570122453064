import React, { useState } from "react";
import { ReactComponent as Star } from "../../../assets/images/Star.svg";

const StarsFeedback = ({ starNumber, starClick }) => {
  return (
    <div style={{ marginBottom: "51px" }} className="stars-feedback-wrapper">
      <Star
        fill={starNumber >= 1 ? "#E3972E" : "#FFFFFF"}
        onClick={() => starClick(1)}
      />
      <Star
        fill={starNumber >= 2 ? "#E3972E" : "#FFFFFF"}
        onClick={() => starClick(2)}
      />
      <Star
        fill={starNumber >= 3 ? "#E3972E" : "#FFFFFF"}
        onClick={() => starClick(3)}
      />
      <Star
        fill={starNumber >= 4 ? "#E3972E" : "#FFFFFF"}
        onClick={() => starClick(4)}
      />
      <Star
        fill={starNumber >= 5 ? "#E3972E" : "#FFFFFF"}
        onClick={() => starClick(5)}
      />
    </div>
  );
};

export default StarsFeedback;
