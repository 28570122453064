import React from "react";
import { SingleCourse } from "../Main/AllCourses/Single_Course/Single_Course.js";
import example_image_course from "../../../assets/images/courses/courses-03.jpg";
import example_image_author from "../../../assets/images/author/author-01.jpg";

const OtherAvaibleCourses = (marginBottom) => {
  return (
    <>
      <div
        className="section section-padding-02"
        style={{ marginBottom: `${marginBottom ? "80px" : ""}` }}
      >
        <div className="container">
          <div className="courses-top">
            <div className="section-title shape-01">
              <h2 className="main-title">Moje wykłady</h2>
            </div>
            {/* <div className="courses-search">
              <form action="#">
                <input type="text" placeholder="Search your course" />
              </form>
            </div> */}
          </div>
          <div className="tab-content courses-tab-content">
            <div className="tab-pane fade show active" id="tabs1">
              <div className="courses-wrapper">
                <div className="row">
                  <SingleCourse
                    pk={1}
                    key={1}
                    course_image={example_image_course}
                    author_image={example_image_author}
                    author_name={"Damian"}
                    category={"Category"}
                    course_title={"course.title"}
                    course_length_h={"08"}
                    course_length_m={"15"}
                    lectures_number={29}
                    price={1221}
                    discount={440}
                    rating={4.9}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherAvaibleCourses;
