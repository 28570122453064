import React from "react";
import { VIDEODETAILSMENU } from "../../settings/enums";

const Menu = ({ setMenuSelect, menuSelect }) => {
  return (
    <div className="details-tab-menu">
      <ul className="nav justify-content-center">
        <li>
          <button
            className={
              menuSelect == VIDEODETAILSMENU.DESCRIPTION ? "active" : ""
            }
            data-bs-toggle="tab"
            data-bs-target="#description"
            onClick={() => setMenuSelect(VIDEODETAILSMENU.DESCRIPTION)}
          >
            Opis kursu
          </button>
        </li>
        <li>
          <button
            className={menuSelect == VIDEODETAILSMENU.OVERVIEW ? "active" : ""}
            data-bs-toggle="tab"
            data-bs-target="#instructors"
            onClick={() => setMenuSelect(VIDEODETAILSMENU.OVERVIEW)}
          >
            Program kursu
          </button>
        </li>
        <li>
          <button
            className={
              menuSelect == VIDEODETAILSMENU.INSTRUCTOR ? "active" : ""
            }
            data-bs-toggle="tab"
            data-bs-target="#instructors"
            onClick={() => setMenuSelect(VIDEODETAILSMENU.INSTRUCTOR)}
          >
            Instruktorzy
          </button>
        </li>
        <li>
          <button
            className={menuSelect == VIDEODETAILSMENU.OPINIONS ? "active" : ""}
            data-bs-toggle="tab"
            data-bs-target="#reviews"
            onClick={() => setMenuSelect(VIDEODETAILSMENU.OPINIONS)}
          >
            Opinie
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
