import React from "react";
import SingleBoxinfo from "./SingleBoxinfo";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { useRef } from "react";
const FeedbackFromStudent = () => {
  const swiperRef = useRef();
  const sliderSettings = {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    992: {
      slidesPerView: 2,
    },
  };

  return (
    <div
      style={{ marginBottom: "100px" }}
      className="section section-padding-02 mt-n1"
    >
      <div className="container">
        {/* Section Title Start */}
        <div className="section-title shape-03 text-center">
          <h5 className="sub-title">Niezdecydowany/a ?</h5>
          <h2 className="main-title">
            Sprawdź opinie naszych studentów <span> Studentów</span>
          </h2>
        </div>
        {/* Section Title End */}
        {/* Testimonial Wrapper End */}
        <div className="testimonial-wrapper">
          <div className="swiper-container">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              // navigation
              pagination={{
                el: ".swiper-custom-pagination",
                clickable: true,
              }}
              slidesPerView={2}
              breakpoints={sliderSettings}
              spaceBetween={30}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              <SwiperSlide>
                <SingleBoxinfo />
              </SwiperSlide>
              <SwiperSlide>
                <SingleBoxinfo />
              </SwiperSlide>
              <SwiperSlide>
                <SingleBoxinfo />
              </SwiperSlide>
            </Swiper>
            {/* Add Pagination */}
            <div
              style={{ width: "100%", height: "18px" }}
              className=" swiper-pagination-clickable swiper-pagination-bullets menu-button-wrapper swiper-custom-pagination"
            >
              {/* <div
								className="menu-span-button "
								tabIndex={1}
								role="button"
								aria-label="Go to slide 1"
							/>
							<div
								className="menu-span-button"
								tabIndex={1}
								role="button"
								aria-label="Go to slide 1"
							/> */}
            </div>
          </div>
        </div>
        {/* Testimonial Wrapper End */}
      </div>
    </div>
  );
};

export default FeedbackFromStudent;
