import React from "react";
import exampleUserMessageAvatar from "../../../assets/images/examplemessageuser.png";

const Thread = ({ active }) => {
  return (
    <div className="single-user active">
      <div className="user-author">
        <img src={exampleUserMessageAvatar} alt="Author" />
      </div>
      <div className="user-content">
        <h6 className="name">Natosha Sibley</h6>
        <p>Woolentor and woocommerce</p>
      </div>
      <span className="time">02 days ago</span>
    </div>
  );
};

export default Thread;
