import config from "../config";
import authHeader from "../helper/auth-header";
import { handleResponse } from "../helper/handle-response";

const register = async (body) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/user/register`, requestOptions);
};

const getTokens = async (body) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/token/`, requestOptions).then((r) => {
    return r.json();
  });
};

const getRefleshToken = async () => {
  const tokens = getCurrentUser();
  //jesli epmpty to logout
  const { refresh } = tokens;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refresh: refresh }),
  };

  return fetch(`${config.apiUrl}/token/refresh/`, requestOptions).then((r) => {
    return r.json().then((r) => {
      if (r.access) {
        localStorage.setItem(
          "tokens",
          JSON.stringify({ ...tokens, access: r.access })
        );
        return { access: r.access };
      } else {
        logout(true, false);
        return false;
      }
    });
  });
};

const login = async (body) => {
  return getTokens(body).then((r) => {
    // success
    if (r.refresh && r.access) {
      localStorage.setItem("tokens", JSON.stringify(r));
      return true;
    }
    //failed
    if (r.detail) {
      throw { error: r.detail };
    } else {
      throw { error: "Błąd logowania" };
    }
  });
};

const getCurrentUser = () => {
  const tokensJson = localStorage.getItem("tokens");
  if (!tokensJson) {
    return false;
  }
  const tokens = JSON.parse(tokensJson);

  if (tokens && Object.keys(tokens).length === 0) {
    return false;
  }

  return tokens;
};

const logout = (redirect, history) => {
  localStorage.removeItem("tokens");

  if (redirect) {
    if (history) {
      history.push("/login");
    } else {
      window.location.href = "/login";
    }
  }
};

const passwordResetSendEmail = async (email) => {
  const auth = authHeader();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: auth },
    body: JSON.stringify({ email: email }),
  };

  return fetch(
    `${config.apiUrl}/user/send-password-reset/`,
    requestOptions
  ).then((r) => {
    return handleResponse(r, requestOptions);
  });
};

const activateAccount = async (body) => {
  const auth = authHeader();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: auth },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/user/activate/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const sendPasswordReset = async (body) => {
  const auth = authHeader();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: auth },
    body: JSON.stringify(body),
  };

  return fetch(
    `${config.apiUrl}/user/confirm-password-reset/`,
    requestOptions
  ).then((r) => {
    return handleResponse(r, requestOptions);
  });
};

export default {
  activateAccount,
  logout,
  register,
  getTokens,
  getCurrentUser,
  login,
  getRefleshToken,
  passwordResetSendEmail,
  sendPasswordReset,
};
