import { useState } from "react";
import SectionHero from "../elements/SectionHero";
import StartACourse from "../elements/About/StartACourse/StartACourse";
import FeedbackFromStudent from "../elements/Main/FeedbackFromStudent/FeedbackFromStudent";
import TeamsMembers from "../elements/About/TeamsMembers/TeamsMembers";

const About = () => {
  const [menu_open, setMenu_open] = useState(false);

  return (
    <>
      <SectionHero
        page_name={"O nas"}
        description={"Poznaj nas "}
        description_light={"bliżej."}
      />
      <StartACourse />
      <TeamsMembers />
      <FeedbackFromStudent />
    </>
  );
};

export default About;
