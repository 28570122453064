import React, { useContext } from "react";
import { CartContext } from "../../../App";

const CourseSidebarInfo = ({ data }) => {
  const { cart, handleSetCart } = useContext(CartContext);
  const handleAddToCart = () => {
    let temp = cart;
    if (temp.length > 0) {
      if (temp.filter((item) => item.pk === data.pk).length <= 0) {
        temp.push(data);
        localStorage.setItem("cart", JSON.stringify(temp));
        handleSetCart(temp);
        console.log(temp);
      }
    } else {
      temp.push(data);
      localStorage.setItem("cart", JSON.stringify(temp));
      handleSetCart(temp);
    }
  };
  return (
    <div className="col-lg-4">
      {/* Courses Details Sidebar Start */}
      <div className="sidebar">
        {/* Sidebar Widget Information Start */}
        <div className="sidebar-widget widget-information">
          <div className="info-price">
            <span className="price">{data.price}zł</span>
          </div>
          <div className="info-list">
            <ul>
              <li>
                <strong>Instruktor</strong>{" "}
                <span>
                  {data.instructors[0].first_name}{" "}
                  {data.instructors[0].last_name}
                </span>
              </li>
              <li>
                <strong>Długość</strong>
                <span>{data.total_duration}</span>
              </li>
              <li>
                <strong>Lekcje</strong> <span>29</span>
              </li>
              <li>
                <strong>Poziom</strong>
                <span>{data.level}</span>
              </li>
              <li>
                <strong>Język</strong>
                <span>{data.language}</span>
              </li>
              <li>
                <strong>Certyfikat</strong>{" "}
                {data.certificate ? <span>Tak</span> : <span>Nie</span>}
              </li>
            </ul>
          </div>
          <div className="info-btn">
            <button
              className="btn btn-primary btn-hover-dark"
              onClick={handleAddToCart}
            >
              Dodaj do koszyka
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSidebarInfo;
