import React from "react";

const Description = ({description}) => {
	return (
		<div
			className="tab-pane fade show active"
			id="description">
			<div className="tab-description">
				<div className="description-wrapper">
					<h3 className="tab-title">Description:</h3>
					<p>
						{description}
					</p>
				</div>
				<div className="description-wrapper">
					<h3 className="tab-title">Curriculum:</h3>
					<p>
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since the 1500s when an unknown printer took a galley of type
						and scrambled it to make a type specimen book. It has survived not
						only five centuries, but also the leap into electronic typesetting,
						remaining essentially unchanged. It was popularsed in the 1960 with
						release containing Lorem Ipsum passages desktop publishing software.
					</p>
				</div>
				<div className="description-wrapper">
					<h3 className="tab-title">Certification:</h3>
					<p>
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since the 1500s when an unknown printer took a galley of type
						and scrambled it to make a type specimen book. It has survived not
						only five centuries, but also the leap into electronic typesetting,
						remaining essentially unchanged. It was popularsed in the 1960 with
						release containing Lorem Ipsum passages desktop publishing software.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Description;
