import React, { useState } from "react";
import { SlideDown } from "react-slidedown";

import "../../../../../node_modules/react-slidedown/lib/slidedown.css";
import SingleLink from "./SingleLink";
import { DISPLAY_SINGLE_COURSE } from "../../../settings/enums";

export const AccordionItem = ({
  setCurrentWatchingVideo,
  setDisplay,
  activeMenu,
  setActiveMenu,
  module,
  time,
  chapters,
  moduleNumber,
}) => {
  const [displayList] = useState(true);

  return (
    <div className="accordion-item">
      <button
        aria-expanded={displayList}
        onClick={() => {
          if (moduleNumber === activeMenu.lesson) {
            setActiveMenu({ ...activeMenu, lesson: null });
            return;
          }
          setActiveMenu({ ...activeMenu, lesson: moduleNumber });
        }}
        className={`${moduleNumber === activeMenu.lesson ? "collapsed" : ""}`}
        type="button"
        tabIndex="0"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
      >
        <p
          style={{
            color: `${
              moduleNumber === activeMenu.lesson ? "#00a3ff" : "#212832"
            }`,
          }}
        >
          {module.name}
        </p>
        <span className="total-duration">{module.total_duration}</span>
      </button>
      <SlideDown closed={!(moduleNumber === activeMenu.lesson)}>
        <nav className="vids">
          {module &&
            module.lessons.map((element, index) => {
              return (
                <SingleLink
                  setDisplay={setDisplay}
                  setCurrentWatchingVideo={setCurrentWatchingVideo}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  chapterNumber={index}
                  key={index}
                  {...element}
                />
              );
            })}
          <SingleLink
            displayStage={DISPLAY_SINGLE_COURSE.QUIZ}
            setDisplay={setDisplay}
            length={"Sprawdź swoją wiedzę z modułu"}
            title={"QUIZ"}
          />
        </nav>
      </SlideDown>
    </div>
  );
};
