import { useEffect } from "react";
import React from "react";
import { LoginBox } from "../elements/Login/LoginBox";
import SectionHero from "../elements/SectionHero";
import { useSearchParams } from "react-router-dom";
import authentication from "../../services/authentication";

const ConfirmAccount = () => {
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const uid = searchParams.get("uid");
    const token = searchParams.get("token");
    if (uid && token) {
      authentication.activateAccount({ uid: uid, token: token }).then((r) => {
        console.log("r esponse");
        console.log(r);
      });
    }
  }, []);
  return (
    <>
      <SectionHero page_name={"Zaloguj się"} description={"Masz już konto ?"} />
      <LoginBox />
    </>
  );
};

export default ConfirmAccount;
