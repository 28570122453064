import React from "react";
import shape_24 from "../../assets/images/shape/shape-24.png";
import author_11 from "../../assets/images/author/author-11.jpg";
import shape_5 from "../../assets/images/shape/shape-5.png";

const SectionHero = ({ page_name, description, description_light }) => {
  return (
    <div className="section page-banner">
      <div className="container">
        {/* Page Banner Start */}
        <div className="page-banner-content">
          <ul className="breadcrumb">
            <li>
              <a href="/#">Home</a>
            </li>
            <li className="active">{page_name}</li>
          </ul>
          <h2 className="title">
            {description} <span>{description_light}</span>
          </h2>
        </div>
        {/* Page Banner End */}
      </div>
      {/* Shape Icon Box Start */}
      <div className="shape-icon-box">
        <img
          className="icon-shape-1 animation-left"
          src={shape_5}
          alt="Shape"
        />
      </div>
      {/* Shape Icon Box End */}
      <img className="shape-3" src={shape_24} alt="Shape" />
      <img className="shape-author" src={author_11} alt="Shape" />
    </div>
  );
};

export default SectionHero;
