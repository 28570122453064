import React from "react";
import RegistraionBox from "../elements/Registration/RegistraionBox";
import SectionHero from "../elements/SectionHero";

const Registration = () => {
  return (
    <>
      <SectionHero
        page_name={"Zarejestruj się"}
        description={"Dołącz do nas! "}
        description_light={""}
      />
      <RegistraionBox />
    </>
  );
};

export default Registration;
