import React from "react";
import localization from "../../../assets/images/icon/localization_contact_icon.svg";
import mail from "../../../assets/images/icon/mail_contact_icon.svg";
import phone from "../../../assets/images/icon/phone_contact_icon.svg";

const element = [
  { title: "Telefon", content: "(88) 193 326 867  ", image: phone },
  { title: "Email Address", content: "hello@lckursy.pl", image: mail },
  { title: "Nasze biuro", content: "Wrocław, Polska", image: localization },
];

const ContactBox = () => {
  return (
    <div
      style={{
        padding: "60px 70px",
        height: "622px",
        borderRadius: "15px",
        background: "#EEF6FB",
        display: "flex",
        flexDirection: "column",
      }}
      className="col-lg-6"
    >
      {element.map((el, index) => {
        return (
          <div key={index} className="contact-single-element">
            <div
              style={{
                width: "100px",
                height: "100px",
                border: "1px solid #005D92",
                borderRadius: "50%",
                padding: "15px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #005D92",
                  borderRadius: "50%",
                  background: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={el.image} alt={"lokalizacja zdjęcie"} />
              </div>
            </div>
            <div className="text-contact-wrapper">
              <h6 className="text-contact-wrapper-title">{el.title}</h6>
              <h6 className="text-contact-wrapper-content">{el.content}</h6>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContactBox;
