import React, { useEffect, useState } from "react";
import courses from "../../../services/courses";

const Quiz = ({ chapter_id }) => {
  const [quizData, setQuizData] = useState();
  const [questionNumber, setQuestionNumber] = useState(0); //index z tablicy quizData

  useEffect(() => {
    courses.getQuizData(1).then((r) => {
      console.log("QUIZ DATA");
      setQuizData(r.questions);
    });
  }, []);

  const clickAnswer = (r) => {};

  return (
    <div className="feedback-container">
      {quizData && (
        <>
          <h6
            style={{
              marginBottom: "36px",
              textAlign: "center",
              fontSize: "25px",
              fontFamily: "Inter",
              fontWeight: "700",
            }}
          >
            {questionNumber + 1}. {quizData[questionNumber].question}
          </h6>
          <ul>
            <li className="quiz-answer-li">
              <div className="quiz-li-circle" />
              {quizData[questionNumber].answer_one}
            </li>
            <li className="quiz-answer-li">
              <div className="quiz-li-circle" />
              {quizData[questionNumber].answer_two}
            </li>
            <li className="quiz-answer-li">
              <div className="quiz-li-circle" />
              {quizData[questionNumber].answer_three}
            </li>
            <li className="quiz-answer-li">
              <div className="quiz-li-circle" />
              {quizData[questionNumber].answer_four}
            </li>
          </ul>
          <div className="quiz-buttons-wrapper">
            {questionNumber > 0 && (
              <button
                style={{
                  width: "114px",
                  height: "60px",
                }}
                className="back-quiz-button"
                onClick={() => setQuestionNumber(questionNumber - 1)}
              >
                WSTECZ
              </button>
            )}
            {questionNumber < quizData.length - 1 && (
              <button
                className="btn btn-primary btn-hover-dark"
                onClick={() => setQuestionNumber(questionNumber + 1)}
              >
                DALEJ
              </button>
            )}
            {questionNumber == quizData.length - 1 && (
              <button
                style={{ display: "flex", justifyContent: "center" }}
                className="btn btn-primary btn-hover-dark"
              >
                Wyślij
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Quiz;
