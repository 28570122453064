import shape_15 from "../../../../assets/images/shape/shape-15.png";
import shape_17 from "../../../../assets/images/shape/shape-17.png";
import shape_16 from "../../../../assets/images/shape/shape-16.png";

const HowItWorks = () => {
  return (
    <div className="section section-padding mt-n1">
      <div className="container">
        {/* Section Title Start */}
        <div className="section-title shape-03 text-center">
          <h5 className="sub-title">Chcę się zapisać!</h5>
          <h2 className="main-title">Jak to działa ?</h2>
        </div>
        {/* Section Title End */}
        {/* How it Work Wrapper Start */}
        <div className="how-it-work-wrapper">
          {/* Single Work Start */}
          <div className="single-work">
            <img className="shape-1" src={shape_15} alt="Shape" />
            <div className="work-icon">
              <i className="flaticon-transparency" />
            </div>
            <div className="work-content">
              <h3 className="title">Wybierz kurs</h3>
              <p>Wybierz interesujący Cię kurs spośród dostępnych na stronie</p>
            </div>
          </div>
          {/* Single Work End */}
          {/* Single Work Start */}
          <div className="work-arrow">
            <img className="arrow" src={shape_17} alt="Shape" />
          </div>
          {/* Single Work End */}
          {/* Single Work Start */}
          <div className="single-work">
            <img className="shape-2" src={shape_15} alt="Shape" />
            <div className="work-icon">
              <i className="flaticon-forms" />
            </div>
            <div className="work-content">
              <h3 className="title">Zarezerwuj kurs</h3>
              <p>Za udział w kursie zapłacisz wygodnie online</p>
            </div>
          </div>
          {/* Single Work End */}
          {/* Single Work Start */}
          <div className="work-arrow">
            <img className="arrow" src={shape_17} alt="Shape" />
          </div>
          {/* Single Work End */}
          {/* Single Work Start */}
          <div className="single-work">
            <img className="shape-3" src={shape_16} alt="Shape" />
            <div className="work-icon">
              <i className="flaticon-badge" />
            </div>
            <div className="work-content">
              <h3 className="title">Zdobądź certyfikat</h3>
              <p>Po ukończonym kursie zdobędziesz certyfikat</p>
            </div>
          </div>
          {/* Single Work End */}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
