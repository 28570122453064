import React, { useContext } from "react";
import { cartItems } from "../../assets/js/cartitems.js";
import { SingleCourse } from "../elements/Main/AllCourses/Single_Course/Single_Course";
import example_image_course from "../../assets/images/courses/courses-03.jpg";
import example_image_author from "../../assets/images/author/author-01.jpg";
import { CartContext } from "../../App";
import { redirect } from "react-router-dom";
import config from "../../config";
import authHeader from "../../helper/auth-header";

const PaymentCart = () => {
  const { cart, handleSetCart } = useContext(CartContext);
  const handleRemoveFromCart = (id) => {
    let temp = cart.filter((item) => item.pk !== id);
    localStorage.setItem("cart", JSON.stringify(temp));
    handleSetCart(temp);
  };
  const handleGoToCheckout = async () => {
    const auth = authHeader();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
      body: JSON.stringify({
        courses: cart.map((item) => item.pk),
      }),
    };
    return await fetch(`${config.apiUrl}/payments/`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((response) => {
        if (response) {
          redirect(response.link);
        }
      });
  };
  return (
    <>
      <main className="shopping-cart-wrapper">
        <header className="shopping-cart-header">
          <div className="container">
            <h1>Mój koszyk</h1>
          </div>
        </header>
        <div className="shopping-cart-box container">
          <section className="cart-items">
            <span className="cart-items-quantity">
              {cart.length === 0
                ? "Brak przedmiotów w koszyku"
                : cart.length === 1
                ? "1 przedmiot w koszyku"
                : `${cart.length} przedmioty w koszyku`}
            </span>
            {cart.map((item, index) => {
              return (
                <div key={index} className="cart-item">
                  <img
                    src={item.cover_image}
                    alt="Miniatura zdjęcia dołączonego do kursu"
                    className="cart-item-image"
                  />
                  <div className="cart-item-details">
                    <h3 className="cart-item-title">{item.title}</h3>
                    <span className="cart-item-author">
                      {item.owner.first_name} {item.owner.last_name}
                    </span>
                    <div className="cart-item-duration">
                      <span className="cart-item-length">
                        {item.total_duration}
                      </span>
                      <span className="cart-item-lectures">
                        {item.lessons_count} lekcji
                      </span>
                    </div>
                  </div>
                  <div className="cart-item-actions">
                    <button
                      className="cart-item-delete"
                      onClick={() => handleRemoveFromCart(item.pk)}
                      tabIndex="0"
                      aria-label="Usuń/Delete"
                    >
                      usuń
                    </button>
                    <div className="cart-item-price">
                      <span className="actual-price">{item.price} zł</span>
                      {/*<span className="former-price">*/}
                      {/*  {item.starting_price}zł*/}
                      {/*</span>*/}
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
          <section className="cart-price">
            <div className="price-container">
              <span className="cart-items-quantity">Podsumowanie:</span>
              <span className="actual-summary-price">
                zł{" "}
                {cart
                  .reduce(
                    (sum, item) =>
                      sum + parseFloat(item.price.replace(",", ".")),
                    0
                  )
                  .toFixed(2)}
              </span>
              <button
                className="checkout-link"
                aria-label="Kasa/Checkout"
                onClick={handleGoToCheckout}
              >
                Do Kasy
              </button>
            </div>
          </section>
        </div>
        <section className="suggested-courses container">
          <h2 className="suggested-header">Mogą Ci się spodobać</h2>
          <div className="suggested-container row">
            {cartItems.map((course, index) => {
              return (
                <SingleCourse
                  pk={course.pk}
                  key={index}
                  course_image={example_image_course}
                  author_image={example_image_author}
                  author_name={"Damian"}
                  category={"Category"}
                  course_title={course.title}
                  course_length_h={"08"}
                  course_length_m={"15"}
                  lectures_number={29}
                  price={course.price}
                  discount={440}
                  rating={4.9}
                />
              );
            })}
          </div>
        </section>
      </main>
    </>
  );
};

export default PaymentCart;
