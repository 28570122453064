import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SingleMenuElement = ({ title, url, underMenu }) => {
  const navigate = useNavigate();
  let location = useLocation();

  return (
    <li>
      <a aria-label={title} className="nav-element" style={location.pathname === `/${url}` ? { color: "#005D92" } : {}} onClick={(e) => {
          e.preventDefault();
          navigate(`/${url}`);
        }} href={`/${url}`}>
        {title}
      </a>
    </li>
  );
};

export default SingleMenuElement;
