import authentication from "../services/authentication.js";

export default function authHeader() {
	const currentUser = authentication.getCurrentUser();
	if (currentUser && currentUser.access) {
		return `Bearer ${currentUser.access}`;
	} else {
		return {};
	}
}
