import React from "react";
import silverMedal from "../../../assets/images/silverMeadal.svg";
import brownMedal from "../../../assets/images/brownMeadal.svg";
import goldMedal from "../../../assets/images/goldMeadal.svg";
import threeCoursesIMG from "../../../assets/images/threeCourses.svg";

const AverageBox = ({ brown, gold, silver, asFirst, threeCourses }) => {
  return (
    <div className="single-medal-box">
      {brown && (
        <>
          <h6 className="medal-title"> {" > 70%"}</h6>
          <img className="medal-img" src={brownMedal} alt="silver medal" />
        </>
      )}
      {silver && (
        <>
          <h6 className="medal-title"> {" > 80%"}</h6>
          <img className="medal-img" src={silverMedal} alt="silver medal" />
        </>
      )}
      {gold && (
        <>
          <h6 className="medal-title"> {" > 100%"}</h6>
          <img className="medal-img" src={goldMedal} alt="silver medal" />
        </>
      )}
      {asFirst && (
        <>
          <img className="medal-img" src={goldMedal} alt="silver medal" />
        </>
      )}
      {threeCourses && (
        <>
          <img className="medal-img" src={threeCoursesIMG} alt="silver medal" />
        </>
      )}
    </div>
  );
};

export default AverageBox;
