export const VIDEODETAILSMENU = {
  DESCRIPTION: "Opis kursu",
  OVERVIEW: "Program kursu",
  INSTRUCTOR: "Instruktor",
  OPINIONS: "Opinie",
};
export const LIVECOURSEMENU = {
  DESCRIPTION: "Opis wykładu",
  OPINIONS: "Opinie",
  INSTRUCTOR: "Instruktor",
  FORUM: "Forum",
  TASKS: "Zadania",
};
export const SINGLECOURSEMENU = {
  DESCRIPTION: "Opis",
  OPINIONS: "Opinie",
  INSTRUCTOR: "Instruktor",
};

export const REGISTERERROR = {
  NOTSAMEPASSWORDS: "Hasła nie są identyczne",
  EMAILERROR: "Niepoprawny adres email",
};

export const DISPLAY_SINGLE_COURSE = {
  QUIZ: "quiz",
  FEEDBACk: "feedback",
  VIDEO: "video",
};
