import React, { useEffect } from "react";
import userImg from "../../../assets/images/Icon.svg";
import frameIMG from "../../../assets/images/Frame.svg";
import shop_icon from "../../../assets/images/shop-icon.svg";
import ShopPreview from "./ShopPreview";
import { useNavigate } from "react-router-dom";
import authentication from "../../../services/authentication.js";

const ProfileElement = () => {
  const navigate = useNavigate();
  useEffect(() => {
    var menuNotificationButton = document.getElementById(
      "notification-menu-button"
    );
    // let shopDropdown = document.getElementById("notification-menu");
    var menuShopButton = document.getElementById("shop-cart-menu-button");
    var menuUserButton = document.getElementById("avatar-menu-button");

    var menuNotification = document.getElementById("notification-menu");
    var menuShop = document.getElementById("shop-menu");
    var menuUser = document.getElementById("user-menu");

    // Obsługa kliknięcia przycisku
    menuNotificationButton.addEventListener("click", function () {
      menuNotification.classList.add("menu-visible"); // Dodajemy/Usuwanie klasę, aby pokazać/ukryć menu
      menuShop.classList.remove("menu-visible");
      menuUser.classList.remove("menu-visible");
    });
    menuShopButton.addEventListener("click", function () {
      menuShop.classList.add("menu-visible"); // Dodajemy/Usuwanie klasę, aby pokazać/ukryć menu
      menuNotification.classList.remove("menu-visible");
      menuUser.classList.remove("menu-visible");
    });
    menuUserButton.addEventListener("click", function () {
      menuUser.classList.add("menu-visible"); // Dodajemy/Usuwanie klasę, aby pokazać/ukryć menu
      menuNotification.classList.remove("menu-visible");
      menuShop.classList.remove("menu-visible");
    });

    // Obsługa kliknięcia poza menu
    document.addEventListener("click", function (event) {
      var targetElement = event.target; // Kliknięty element
      // Sprawdzamy, czy kliknięty element znajduje się wewnątrz menu
      var isClickInsideMenu = menuNotification.contains(targetElement);
      var isClickButton = menuNotificationButton.contains(targetElement);

      var isClickInsideShopMenu = menuShop.contains(targetElement);
      var isClickShopButton = menuShopButton.contains(targetElement);

      var isClickInsideUserMenu = menuUser.contains(targetElement);
      var isClickUserButton = menuUserButton.contains(targetElement);

      // Jeśli kliknięty element nie należy do menu i menu jest widoczne, ukrywamy je
      if (
        !isClickInsideMenu &&
        !isClickInsideShopMenu &&
        !isClickInsideUserMenu &&
        !isClickButton &&
        !isClickUserButton &&
        !isClickShopButton
      ) {
        menuNotification.classList.remove("menu-visible");
        menuShop.classList.remove("menu-visible");
        menuUser.classList.remove("menu-visible");
      }
    });
  }, []);

  return (
    <div className="profile_element_container">
      <ul className="wrapper">
        <li
          id="notification-menu-button"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "24px",
            padding: "10px",
          }}
          tabIndex="0"
          aria-label="Podgląd koszyka"
          className="icon-header-item"
        >
          <button style={{ border: "none", background: "transparent" }}>
            <img style={{ width: "30px", height: "30px" }} src={shop_icon} />
          </button>
        </li>
        <li
          className="icon-header-item"
          id="shop-cart-menu-button"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "37px",
          }}
          tabIndex="0"
          aria-label="Podgląd powiadomień"
        >
          <button style={{ border: "none", background: "transparent" }}>
            <img style={{ width: "29px", height: "29px" }} src={frameIMG} />
          </button>
        </li>
        <li>
          <button
            className="icon-header-item"
            style={{ border: "none", background: "transparent" }}
          >
            <div
              tabIndex="0"
              aria-label="Podgląd użytkownika"
              id="avatar-menu-button"
              className="avatar-box"
            >
              <img src={userImg} />
            </div>
          </button>
        </li>
      </ul>

      <ShopPreview />

      <div id="shop-menu" className="shop-cart-menu">
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            color: "#2C2C2C",
            fontSize: "20px",
            fontFamily: "Inter",
            fontWeight: "500",
            lineHeight: "26.4px",
          }}
        >
          <li style={{ marginBottom: "25px" }}>Moje powiadomienia</li>
          <li></li>
          <li></li>
        </ul>
      </div>

      <div id="user-menu" className="user-menu">
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            color: "#2C2C2C",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "26.4px",
          }}
          className="font-class-headermenu"
        >
          <li
            className="user-menu-element-text"
            style={{ marginBottom: "25px" }}
            onClick={(e) => {
              e.preventDefault();

              navigate(`/profile`);
            }}
          >
            Profil
          </li>
          <li
            className="user-menu-element-text"
            style={{ marginBottom: "25px" }}
          >
            Wiadomości
          </li>
          <li
            className="user-menu-element-text"
            style={{ marginBottom: "25px" }}
          >
            Pomoc
          </li>
          <li>
            <div
              style={{
                width: "130px",
                height: "1px",
                background: "#000",
                marginBottom: "25px",
              }}
            />
          </li>
          <li
            className="user-menu-element-text"
            onClick={() => authentication.logout(true, false)}
          >
            Wyloguj się
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileElement;
