import React, { useEffect, useState } from "react";
import example_image_course from "../../../assets/images/courses/courses-03.jpg";
import example_image_author from "../../../assets/images/author/author-01.jpg";
import courses from "../../../services/courses.js";
import SingleCourse from "./SingleCourse.js";
import { useQuery } from "react-query";
import LoadingSpinner from "../LoadingSpinner.js";

const MyOwnedCourses = () => {
  const [page] = useState(1);

  const { isLoading, data } = useQuery({
    queryKey: ["my_courses", page],
    queryFn: () => courses.getCurrentUserCoursesList(page),
    keepPreviousData: true,
  });

  useEffect(() => {
    console.log("data");
    console.log(data);
  }, [data]);

  return (
    <div className="section section-padding">
      <div className="container">
        <div
          style={{
            justifyContent: "start",
            backgroundColor: "white",
            padding: 0,
          }}
          className="courses-category-wrapper"
        >
          <div className="section-title shape-01">
            <h2 className="main-title">Kontynuuj naukę</h2>
          </div>
        </div>
        <div className="courses-wrapper-02">
          <div className="row">
            {isLoading && !data ? (
              <LoadingSpinner />
            ) : (
              data.map((course, index) => {
                return (
                  <SingleCourse
                    key={index}
                    courseImage={course.cover_image}
                    authorImage={example_image_author}
                    author={course.owner}
                    title={course.title}
                    complete={0}
                    category={course.tag[0].name}
                    // complete={Number(
                    //   course.course_users[0]?.percentage_completed || 0
                    // )}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOwnedCourses;
