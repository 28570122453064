export const mainMenu = {
  Home: {
    title: "Strona Główna",
    url: "",
  },
  All: {
    title: "Katalog Kursów",
    url: "courses",
    underMenu: {
      Courses: {
        title: "Kursy",
        url: "courses",
      },
      CoursesDetails: {
        title: "Informacje o kursie",
        url: "",
      },
    },
  },
  Pages: {
    title: "Wykłady",
    url: "about",
  },
};
