import React, { useState } from "react";
import StarsFeedback from "./StarsFeedback";

const Feedback = () => {
  const [stage, setStage] = useState(1);
  const [rate, setRate] = useState();

  const starClick = (start_order) => {
    if (stage == 1) {
      setRate(start_order);
      setStage(2);
    }
  };
  return (
    <div className="feedback-container">
      <h6
        style={{
          marginBottom: "36px",
          textAlign: "center",
          fontSize: "25px",
          fontFamily: "Inter",
          fontWeight: "700",
        }}
      >
        {stage == 1 &&
          "Gratulujemy zakończenia kursu. Jak oceniasz dotychczasowe doświadczenie z kursem?"}
        {stage == 2 && "Co skłoniło Cię do wystawienia takiej oceny?"}
      </h6>
      <StarsFeedback starNumber={rate} starClick={starClick} />
      {stage == 2 && (
        <textarea
          placeholder="Podziel sie opinią..."
          style={{ marginBottom: "41px" }}
          className="feedback-text-area"
        />
      )}
      {stage == 1 && (
        <button className="send-feedback-aske-me">Zaspytaj mnie później</button>
      )}
      {stage == 2 && (
        <button className="send-feedback-button">Prześlij opinię</button>
      )}
    </div>
  );
};

export default Feedback;
