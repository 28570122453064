import React from "react";
import SectionHero from "../elements/SectionHero";
import ContactBox from "../elements/Contact/ContactBox";

export const Contact = () => {
  return (
    <>
      <SectionHero
        page_name={"Kontakt"}
        description={"Poznajmy się! "}
        description_light={""}
      />
      <div className="section section-padding">
        <div className="container">
          <div className="register-login-wrapper">
            <div className="row align-items-center">
              <ContactBox />
              <div className="col-lg-6">
                <div className="register-login-form">
                  <h3 className="title">Napisz do nas!</h3>
                  <div className="form-wrapper">
                    <form action="#">
                      <div className="single-form">
                        <input type="text" placeholder="Imię" />
                      </div>

                      <div className="single-form">
                        <input name="email" placeholder="Email" />
                      </div>
                      <div className="single-form">
                        <input type="text" placeholder="temat" />
                      </div>
                      <div className="single-form">
                        <textarea type="text" placeholder="" name="password" />
                      </div>

                      <div className="single-form">
                        <button className="btn btn-primary btn-hover-dark w-100">
                          Wyślij wiadomość
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
