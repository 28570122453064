import React, { useState } from "react";
import authentication from "../../../services/authentication";
import RegisterLoginImage from "../RegisterLoginImage";
import { useNavigate } from "react-router-dom";
import RememberMe from "./elements/RememberMe";
import ForgotPassword from "./elements/ForgotPassword";

export const LoginBox = () => {
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const typeInfo = (e) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
    setError("");
  };

  const login = async (e) => {
    e.preventDefault();
    authentication
      .login(loginInfo)
      .then((r) => {
        if (r) {
          navigate("/");
        }
      })
      .catch((error) => {
        setError(error.error);
      });
  };
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="register-login-wrapper">
          <div className="row align-items-center">
            <RegisterLoginImage />
            <div className="col-lg-6">
              <div className="register-login-form">
                <h3 className="title">Zaloguj się</h3>
                <div className="form-wrapper">
                  <form action="#">
                    <div className="single-form">
                      <input
                        style={error ? { border: "1px solid #B2282D" } : {}}
                        name="email"
                        type="email"
                        placeholder="Username or Email"
                        value={loginInfo.email}
                        onChange={(e) => typeInfo(e)}
                      />
                    </div>
                    <div className="single-form">
                      <input
                        style={error ? { border: "1px solid #B2282D" } : {}}
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={loginInfo.password}
                        onChange={(e) => typeInfo(e)}
                      />
                    </div>
                    {error && <p className="error-text">{error}</p>}
                    <div className="single-form">
                      <button
                        onClick={(e) => login(e)}
                        className="btn btn-primary btn-hover-dark w-100"
                      >
                        Zaloguj
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                      className="single-form"
                    >
                      <RememberMe />
                      <ForgotPassword />
                    </div>
                    <div style={{ marginTop: "220px" }} className="single-form">
                      <p
                        style={{
                          color: "#2C2C2C",
                          fontSize: "14px",
                          fontFamily: "Inter",
                          letterSpacing: "-0.56px",
                        }}
                      >
                        NIe masz jeszcze konta?{" "}
                        <a style={{ textDecoration: "underline" }} href="#">
                          Utwórz konto
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
