import React from "react";

const RememberMe = () => {
  return (
    <div
      style={{ display: "flex", justifyConten: "center", alignItems: "center" }}
    >
      <input
        aria-required={true}
        aria-placeholder={"zapamiętaj mnie"}
        style={{
          display: "inline-block",
          border: "2px solid red",
          height: "20px",
          width: "20px",
        }}
        type="checkbox"
        id="vehicle1"
        name="vehicle1"
        value="Bike"
      />
      <label
        style={{
          color: "#2C2C2C",
          fontSize: "14px",
          fontFamily: "Inter",
          textDecorationLine: "underline",
        }}
      >
        Zapamiętaj dane logowania
      </label>
    </div>
  );
};

export default RememberMe;
