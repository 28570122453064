import React, { useEffect } from "react";
import Feedback from "./Feedback";
import Quiz from "./Quiz";
import { DISPLAY_SINGLE_COURSE } from "../../settings/enums";
import ReactPlayer from "react-player";

export const VideoContent = ({ display, chapter_id, videoUrl }) => {
  useEffect(() => {
    console.log("videoUrl in content");
    console.log(videoUrl);
  }, [videoUrl]);

  return (
    <div className="vidcontainer">
      {display == DISPLAY_SINGLE_COURSE.FEEDBACk && <Feedback />}
      {display == DISPLAY_SINGLE_COURSE.QUIZ && (
        <Quiz chapter_id={chapter_id} />
      )}
      {/* tutaj jest wideo */}
      {/* <video id="myvid" /> */}
      {videoUrl && display == DISPLAY_SINGLE_COURSE.VIDEO && (
        <ReactPlayer controls width={"100%"} height={"100%"} url={videoUrl} />
      )}

      {/* <div className="video-play-bar">
        <div className="topControl">
          <div className="progress">
            <span className="bufferBar" />
            <span className="timeBar" />
          </div>
          <div className="time">
            <span className="current" /> /
            <span className="duration" />
          </div>
        </div>
        <div className="controllers">
          <div className="controllers-left">
            <button className="prevvid disabled" title="Previous video">
              <i className="icofont-ui-previous" />
            </button>
            <button className="btnPlay" title="Play/Pause video" />
            <button className="nextvid" title="Next video">
              <i className="icofont-ui-next" />
            </button>
            <button className="sound sound2" title="Mute/Unmute sound" />
            <div className="volume" title="Set volume">
              <span className="volumeBar" />
            </div>
          </div>
          <div className="controllers-right">
            <button className="btnspeed" title="Video speed">
              <i className="fa fa-gear" />
            </button>
            <ul className="speedcnt">
              <li className="spdx50">1.5</li>
              <li className="spdx25">1.25</li>
              <li className="spdx1 selected">Normal</li>
              <li className="spdx050">0.5</li>
            </ul>
            <button className="btnFS" title="full screen" />
          </div>
        </div>
      </div> */}
      {/* <div className="bigplay" title="play the video">
        <i className="fa fa-play" />
      </div> */}
      {/* <div className="loading">
        <div className="spinner-border spinner">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div> */}
    </div>
  );
};
