import { Routes, Route } from "react-router-dom";
import React from "react";
import routerList from "./routerList";
import RouteWrapper from "./components/elements/Wrapper/RouteWrapper";

export const routes = (
	<Routes>
		{routerList.map(({ path, component }) => {
			return (
				<Route
					key={`${path}`}
					path={path}
					element={<RouteWrapper children={component} />}
				/>
			);
		})}
		<Route
			path="*"
			element={<p>Not found page</p>}
		/>
	</Routes>
);
